@import '../../../styles/toolkit';

.filePickerInputContainer {
  width: 100%;
  cursor: pointer;
  
  label.filePickerLabel {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    border-radius: 100px;
    font-family: Haffer;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    padding: spacing(2);
    color: map-get($typography, light);
    background-color: map-get($background, dark);
    cursor: pointer;


    // &:hover {
    //   background-color: map-get($background, medium);
    // }

    &:active {
      background-color: map-get($background, medium);
    }
  }

  .filePickerForm {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: spacing(2);
    margin: spacing(2) 0;
  }
}