@import "../../styles/toolkit";

#actionDetailsMobile {
  animation: slideInRight 0.5s forwards;
  position: relative;
  background-color: map-get($background, light);
  min-height: 100vh;
  height: fit-content;
  width: 100%;
  overflow-wrap: normal; 
  padding-top: 64px;

  >.actionDetailsNavigationContainer {
    padding: spacing(2) spacing(2) spacing(1) spacing(2);
    background-color: map-get($background, dark);
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .actionDetailsNavigationTitle {
      color: map-get($typography, medium);
      font-family: Haffer;
      font-size: 15px;
      font-style: normal;
      font-weight: 600;
      line-height: 23.677px; /* 157.848% */
      text-transform: uppercase;
      text-align: center;
      flex-grow: 1;
    } 
    

    .actionDetailsExitButton {
      position: absolute; // Position the button absolutely...
      right: spacing(2);  

      a {
        svg {
          // margin: spacing(1) spacing(1) 0 0 ;
          fill: map-get($typography, medium );
        }
      }
    }
  
  }

  > .actionDetailsContainer > .actionDetailsStatusContainer {
    // max-width: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: spacing(2);
    margin: spacing(4) spacing(2) spacing(2) spacing(2);

    svg {
      width: 80px;
      height: 80px;
      margin: 0 spacing(2) 0 0;
    }

    .statusNotificationContent {
      p.MuiTypography-root.MuiTypography-body1.statusNotificationTitle {
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 150%; /* 24px */
        letter-spacing: 0.16px;

        &.completed {
          color: map-get($success, main);
        }
      }

      p.MuiTypography-root.MuiTypography-body1.statusNotificationMessage {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        letter-spacing: 0.16px;


        &.completed {
          color: map-get($success, main);
        }
      }
    }
  }

  >.actionDetailsContainer {
    .actionDetailsHeader {    
      .actionDetailsTitle {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin: spacing(6) 0;
  
        .circleIconContainer {
          margin-bottom: spacing(2);
  
          >.circleBackground {
            width: 64px;
            height: 64px;
            svg {
              color: map-get($border, default );
              width: 40px;
              height: 40px;
            }
          }
        }
  
  
        .MuiTypography-body1 {
          margin-left: spacing(1);
          margin-bottom: spacing(2);
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%; /* 24px */
          letter-spacing: 0.16px;
        }
  
        .MuiTypography-h4 {
          text-align: center;
          font-size: 20px;
          font-style: normal;
          font-weight: 400;
          line-height: 144%; /* 28.8px */
          letter-spacing: -0.2px;
          // max-width: 300px;
          margin: 0 spacing(4);
        }
  
      }
    }
  
    .actionDetailsDivider {
      background-color: map-get($border, default);
      height: 1px;
      width: 100%;
      margin: spacing(3) 0
    }
  
  
    >.actionDetailsBody {
      @include flex-column-center;
      width: 100%;
      padding: 0 0 spacing(8) 0;
  
      .actionContent {
        @include flex-column-center;
        margin-bottom: spacing(5);
        width: 100%;
        
        .actionDetailsDescription {
          margin: 0 spacing(4);
        }
  
        .appointmentDetailsBodyText  {
          margin-block-start: 0;
          margin-block-end: 0;
          margin-inline-start: 0px;
          margin-inline-end: 0px;
          margin-left: spacing(6);
          // margin-right: spacing(6);
        }
  
        .actionDetailsDescription {
          margin-bottom: spacing(4);
        }
  
        .actionDetailsButtonContainer {
          width: 100%;
          padding: 0 spacing(4);
          display: flex;
          align-items: center;
        }
  
        button.actionDetailsButton {
          margin: spacing(2) 0 0 0;
          width: 100%;
          text-align: center;
        }
  
        a.actionDetailsButton {
          margin: 0 spacing(2) spacing(4) spacing(2);
          width: 100%;
          .actionButtonText {
            @include flex-center;
          }
        }
  
      }
      
      .actionDetailsBodyHeader {
        background-color: map-get($background, medium-light);
        border-radius: 25px;
        text-align: center;
        color: map-get($typography, medium-light);
        width: fit-content;
        padding: spacing(.5) spacing(2);
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 150%; /* 24px */
        letter-spacing: 0.16px;
        // align-self: center;
        margin: 0 0 spacing(2) 0;
      }
  
      .actionDetailsNeedHelpContainer {
        display: flex;
  
        a.cardLinkWrapper {
          text-decoration: none;
        }
  
        .actionDetailsNeedHelp {
          @include flex-center();
          padding: spacing(1);
          margin: spacing(2);
          max-width: 360px;
  
          .cardBody {
            @include flex-center;
  
            >.MuiAvatar-root {
              padding: spacing(1);
              width: 60px;
              height: 60px;
            }
        
            .MuiTypography-body1 {
              span {
                font-weight: 700;
              }
            }
          }
  
          .cardFooter {
            display: none;
          }
        }
      }
    }
  }
}