@import '../../../../styles/toolkit';


.notificationItemContainer {
  width: 100%;
  height: 100%;
  background-color: map-get($base-tints, base-2-60);

  @include respond-to(md) {
    background-color: map-get($base-tints, base-2-80);
  }
  // flex:1;
  display: flex;
  align-items: center;
  padding: 0;
  border-radius: 0;
  border-bottom: 1px solid rgba(map-get($base-tints, base-4-40), 0.42);

  &.unread {
    background-color: map-get($base-tints, base-2-40);

    @include respond-to(md) {
      background-color: map-get($base-tints, base-2-60);
    }
  }

  .unreadIndicatorBar {
    width: 6px;
    align-self: stretch;
    background-color: map-get($base-tints, base-3-40);

    &.unread {
      background-color: map-get($accent-tints, accent-3-60);
    }
  }
  
  .cardContent {
    display: flex;
    flex-direction: column;
    gap: spacing(2);
    align-items: flex-start;
    padding: spacing(2);
    width: 100%;

    .normalTitle {
      text-align: left;
      font-size:16px;
    }

    .normalBody {
      text-align: left;
      font-size: 14px;
    }

    .mobileTitle {
      text-align: left;
      font-size: 18px;
    }

    .mobileBody {
      text-align: left;
      font-size: 14px;
    }

    .createdAt {
      color: map-get($base-tints, base-4-60);
      font-size: 14px;
    }
  }


  .unreadIndicator {
    width: 11px;
    height: 11px;
    border-radius: 50%;
    background-color: map-get($accent-colors, accent-3);
    margin-right: spacing(2);
  }
}