@import '../../styles/toolkit';

#appHeader.headerContainer {
  @import '../../styles/typography_overrides.scss';

  &.fixed {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    // height: 100%;

    @include respond-to(md) {
      position: relative;
    }
  }

  height: 64px;
  width: 100%;
  z-index: 900;
  padding: spacing(2);
  display: flex;
  gap: spacing(4);
  align-items: center;
  justify-content: space-between;
  background-color: map-get($background, dark);

  .headerLogoSvg {
    width: 75px;
    height: 22px;
  }

  .headerLogoPath {
    fill: map-get($primary, main);
  }

  .svgHeaderMenuIcon {
    width: 30px;
    height: 30px;
  }

  .pathHeaderMenuIcon {
    fill: map-get($primary, main);
  }

  .rightSide {
    display: flex;
    align-items: center;
    justify-items: center;

    > .bellContainer {
      position: relative;
    }
  }

  // Desktop styles
  @include respond-to(md) {
    grid-area: header;
    padding: spacing(2) spacing(2) spacing(2) spacing(3);
    margin: 0;
    width: 100%;
    position: fixed;
  }
}

#appSubheader.subheaderContainer {
  // Base styling of the container
  padding: spacing(6) 0;
  // border-bottom: 1px solid map-get($border, default);
  width: 100%;
  background-color: map-get($background, light);

  .headerTitle {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;


    .previewText {
      border: 2px solid map-get($typography, light);
      border-radius: 8px;
      color: map-get($typography, light);
      font-family: Haffer;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      margin: 0 0 spacing(2);
      padding: spacing(1) spacing(2);

      @include respond-to(md) {
        color: map-get($typography, main);
        border-color: map-get($typography, main);
      }
    }

    .title {
      font-weight: 500;
      font-style: italic;
      font-size: 17px;
      color: map-get($typography, medium-light);
    }
    .altTitle {
      display: none;
    }
    .subtitle {
      font-weight: 500;
      font-size: 32px;
      margin: spacing(1) 0;
    }
    .altSubtitle {
      display: none;
    }
    .subtitleDate {
      font-size: 14px;
      color: lighten(map-get($typography, main), 30%);
    }
  }

  @include respond-to(md) {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 0;
    background-color: map-get($background, medium-light);

    .headerTitle > h4 {
      display: inline-block;
    }

    .adminHeaderTools {
      display: flex;
      margin-top: spacing(2);

      button {
        margin-right: spacing(2);
        background-color: map-get($background, medium);
      }
    }
  }
}