@import '../../../styles/toolkit';

.documentsListContainer {
  padding-bottom: spacing(8);

  @include respond-to(md) {
    padding: 0 spacing(8) 0 spacing(8);
  }

  .documentsListFileHeader {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: spacing(2) 0 spacing(2) 0;

    .folderIcon {
      margin-right: spacing(2);
    }
  }

  .documentOptionsContainer {
    display: flex;
    flex-direction: column;

    .adminButtons {
      display: flex;
      justify-content: flex-end;

      button.addFolderButton {
        margin: spacing(2);
      }
    }

    .filesToMoveList {
      margin: spacing(1);
      padding: spacing(2);
      background-color: map-get($base-colors, base-1);
    }

    .moveButtons {
      display: flex;
      align-items: center;
      gap: spacing(2);

      // button.clearFilesButton {
      //   margin-top: spacing(2);
      // }
    }
  }

  ul.folderList {
    all: unset;

    .fileList {
      @include respond-to(md) {
        border-radius: 10px;
      }
    }

  }
}