@import '../../styles/toolkit';

div.estateValueOuterContainer {
  div.arangeDisplay {
    align-items: center;
    display: flex;
    margin-right: 10px;
  }

  div.arangeDisplayTop {
    justify-content: center;
    display: flex;
    margin-top: 10px;
  }

  div.standardScrollDivider {
    width: 100%;
    max-width: 600px;
  }

  div.scrollContainer {
    display: flex;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    scrollbar-width: none;
    -ms-overflow-style: none;
  }

  div.scrollContainerInternalCenter {
    flex: 0 0 100%;
    scroll-snap-align: start;
    min-height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
  }

  div.scrollContainerInternalStart {
    flex: 0 0 100%;
    scroll-snap-align: start;
    min-height: 200px;
    display: flex;
    align-items: start;
    justify-content: start;
    font-size: 24px;
  }

  div.scrollIcons {
    margin: 0 5px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
  }

  div.adminEstateReadySwitch {
    align-self: flex-end;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    >.MuiSwitch-root {
      margin-right: spacing(2);
    }
  }

  div.estateValueContainer {
    @include flex-column-center;
    width: 100%;
    padding: spacing(4) 0;
    background-color: map-get($background, light);

    >.sampleText {
      border: 2px solid map-get($typography, main);
      border-radius: 8px;
      font-family: Haffer;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      margin: 0 0 spacing(2);
      padding: spacing(1) spacing(2);
    }

    >div.chartContainer {
      margin-bottom: spacing(4);
    }

    >div.estateComponentGroupsWithColor {
      display: grid;
      grid-template-columns: repeat(2, 1fr); // Creates a 2-column grid
      column-gap: 40px;
      row-gap: 20px;
      width: 100%;
      padding: 0 spacing(4);

      >div.estateComponentGroup {
        display: flex;
        flex-direction: row;
        align-items: center;

        &:nth-child(odd) {
          justify-content: flex-end; // Align content to the right for odd items

          >.legendText {
            width: 80px;
          }
        }

        &:nth-child(even) {
          justify-content: flex-start; // Align content to the left for even items
        }

        >.circle {
          min-width: 15px;
          width: 15px;
          height: 15px;
          border-radius: 50%;
          margin-right: spacing(1);
        }

        >.legendText {
          white-space: normal;
          text-wrap: wrap;
        }
      }
    }
  }

  @include respond-to(md) {
    div.estateComponentGroupsWithColor {
      max-width: 504px;
    }
  }

  @include respond-to(lg) {
    padding: 0 spacing(13);

    div.estateValueContainer {
      border-radius: 0 0 8px 8px;

      >div.estateComponentGroupsWithColor {
        grid-template-columns: repeat(3, 1fr); // Creates a 2-column grid

        > div.estateComponentGroup {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-starts;

          &:nth-child(odd) {
            justify-content: flex-start;
          }

          &:nth-child(even) {
            justify-content: flex-start;
          }
        }

        //   justify-content: center;

        //   >div.estateComponentGroup {
        //     flex: 0 0 calc(33.33% - 20px);
        //   }
      }
    }
  }
}