@import '../../styles/toolkit';

div.actionCardContainer {
  background-color: map-get($background, light);
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.04), 0px 1px 1px 0px rgba(0, 0, 0, 0.04), 0px 3px 3px 0px rgba(0, 0, 0, 0.03), 0px 6px 3px 0px rgba(0, 0, 0, 0.02), 0px 10px 4px 0px rgba(0, 0, 0, 0.01), 0px 16px 4px 0px rgba(0, 0, 0, 0.00);

  @media (min-width: 1024px) {
    min-width: 387px;
  }

  div.actionCardHeader {
    @include flex-center-start();
    flex-direction: row;

    .MuiTypography-body1 {
      font-family: Haffer, Arial, sans-serif;
      margin-left: spacing(2);
    }
  }

  div.actionCardBody {
    margin-bottom: spacing(2);

    .MuiTypography-body1 {
      font-family: Haffer, Arial, sans-serif;
      font-size: 20px;
      font-weight: 400;
    }
  }

  ;

  div.actionCardButtonContainer {
    width: 100%;
    padding: 0;
    margin: 0;

    a.actionCardButton {
      width: 100%;
      height: 48px;
      border-radius: 0;
      justify-content: flex-start;

      p {
        margin-right: spacing(1);
        margin-left: spacing(2);
      }
    }
  }

  ;
}

.actionCardProgressContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  opacity: 0.6;
  padding: spacing(2);

  &.attention,
  &.completed {
    opacity: 1;
  }

  >p.MuiTypography-root.MuiTypography-body1 {
    font-family: "GalaxieCopernicus";
    font-size: 12px;
    font-style: italic;
    font-weight: 500;
    line-height: 150%;
    /* 18px */
    letter-spacing: -0.12px;

    &.attention {
      color: map-get($status, attention);
      opacity: 1;
    }

    &.completed {
      color: map-get($status, complete);
      opacity: 1;
    }

  }

  svg {
    margin-right: spacing(2);
    width: 32px;
    height: 32px;
  }
}

div.actionCardAdminContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
  // margin-top: spacing(4);
  padding: spacing(1);
  background-color: map-get($background, light);
  border-top: 1px solid map-get($primary, dark);

  >.adminActionButtons {
    display: flex;

    button.adminActionButton {
      margin-left: spacing(2);
    }
  }
}