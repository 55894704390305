@import "../../../../styles/toolkit";

.customerSignUpUserFormContainer {
  @include authentication-form-styling();
}


.signUpLoadingOverlay {
  @include flex-center();
  width: 100%;
  height: 20vh;
}