@import '../../../styles/toolkit';

div.selectAnEstateContainer {
  min-width: 400px;
  z-index: 1000;

  label {
    color: map-get($typography, main);
  }

  .selectEstateFormControl {
    background-color: white;
  }
}