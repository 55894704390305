@import '../../../styles/toolkit';


.filesListContainer {
  width: 100%;
  padding: spacing(2);
  
  @include respond-to(md) {
    padding: spacing(2) spacing(4);
    background-color: map-get($base-colors, base-1);
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  p.fileListTitle {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    margin-bottom: spacing(2);
  }

  ul {
    list-style-type: none; /* Removes the bullet points */
    margin: 0; /* Removes the margins */
    padding: 0; /* Removes the padding */
  }
}