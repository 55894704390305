@import "../../styles/toolkit";

#mobilePageLayout {
  #appSubheader {
    background-color: map-get($base-colors, base-1);
  }

  @include flex-column;
  background-color: map-get($background, medium-light);
  position: relative;
  &.fixed {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  > .pageContent {
    flex-grow: 1;
    -webkit-overflow-scrolling: touch;
    height: calc(100vh - 64px);

    > .formModal {
      z-index: 200;
    }
  }
}