@import "../../../../styles/toolkit";
.deleteOneContentFormContainer {
  @include default-form-styling-delete();

  .formHeader {
    margin-bottom: spacing(2);
  }

  .formBody {
    margin-bottom: spacing(2);
      p {
        margin-bottom: spacing(2);
      }
  }

}