@import '../../../styles/toolkit';

div.acceptEulaContainer {
  min-height: 600px;

  div.welcomeHeaderContainer {
    padding: 0 spacing(2);
    margin-top: spacing(4);
    margin-bottom: spacing(3);

    h1.welcomeTitle {
      font-size: 34px;
      margin-bottom: spacing(2);
    }

    p.welcomeMessage {
      font-size: spacing(2);
      font-weight: 400;
      margin-bottom: spacing(2);
    }

    ul.documentLinks {
      margin-top: 0;

      > li:not(:last-child) {
        margin-bottom: spacing(1);
      }
    }
  }

  div.eulaNoticeContainer__outer {
    padding: spacing(0) spacing(2);
    width: 100%;
    margin-bottom: spacing(3);

    div.eulaNoticeContainer {
      background-color: map-get($background, medium-light);
      border-radius: 8px;
      padding: spacing(1);
      width: 100%;
  
      >.noticeContainer {
        display: flex;
        align-items:center;
        justify-content: flex-start;
  
        >div.checkBoxContainer {
          margin-right: spacing(1);
  
          > input {
            background-color: map-get($background, medium-light);
            margin: 0;
          }
        }
  
        .serviceAgreementText,
        a.termsOfServiceLink,
        a.privacyNoticeLink {
          font-size: spacing(1.25);
          color: map-get($typography, main);
          text-decoration-color: map-get($typography, main);
        }
      }
  
      >p.errorsMessage {
        text-align: center;
        color: map-get($typography, error);
      }
    }
  }

  div.buttonsContainer {
    padding: 0 spacing(2);
    width: 100%;
    margin-bottom: spacing(4);

    button.submitEulaAgreementButton {
      width: 100%;
      text-transform: none;
      border-radius: 24px;
      height: 48px;
      font-size: 16px;
    }
  }

  @include respond-to(lg) {
    margin-top: spacing(20);
    max-width: 390px;
    padding: 0;

    div.welcomeHeaderContainer {
      margin-top: 0;
      margin-bottom: spacing(3);
      padding: spacing(2) 0 0 0;
    }

    div.eulaNoticeContainer__outer {
      padding: 0;
    }

    div.buttonsContainer {
      padding: 0;
    }
  }
}