@import '../../../styles/toolkit';

.uploadFileContainer {
  padding: spacing(2);

  @include respond-to('md') {
    display: flex;
    gap: spacing(4);
  }
  .uploadFileDropZone {
    display: flex;
    padding: 24px 64px;
    flex-direction: column;
    align-items: center;
    gap: 32px;
    width: 100%;
    align-self: stretch;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='24' ry='24' stroke='%23333' stroke-width='1' stroke-dasharray='6%2c 14' stroke-dashoffset='8' stroke-linecap='square'/%3e%3c/svg%3e");
    border-radius: 24px;

    label.filePickerLabel {
      background-color: transparent;

      &:active {
        background-color: transparent;
      }

      
      p {
        font-weight: 500;
        
        &:hover {
          color: map-get($typography, medium);
        }

        span {
          text-decoration: underline;
        }
      }
    }

  }
}