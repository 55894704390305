@import "../../../styles/toolkit";

div.actionContent {
  @include flex-column-center;

  @include respond-to(md) {
    max-width: 650px;
    align-items: flex-start;
  }
   
  .MuiTypography-body1.manualActionBodyText {
    width: 100%;
    text-align: start;
    
    margin-bottom: spacing(2);
  }

  .MuiList-root {
    align-self: flex-start;
    padding-left: spacing(2);
    margin-bottom: spacing(1);

    .MuiListItem-root {
      margin: 0;
      padding: 0;
    }
  }

  p.MuiTypography-body1.appointmentDetailsBodyText {
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    margin-bottom: spacing(2);
    width: 100%;
    padding: 0 spacing(2);
    text-align: left;

    @include respond-to(md) {
      width: 100%;
      text-align: start;
    }
  }

  button.addToCalendarButton {
    width: 80%;
    max-width: 281px;
    margin-bottom: spacing(2);
    align-self: flex-start;
  }

  button.notificationButton {
    width: 100%;
    // max-width: 400px;
    margin-bottom: spacing(2);

    .MuiTypography-body1 {
      font-size: 20px;
    }

    > p.notificationButtonText {
      color: map-get($typography, dark);
    }
  }

  .linkButtonContainer {
    width: 100%;
    padding: 0 spacing(4);
    
    a.downloadLinkButton {
      width: 100%;
      box-sizing: border-box;
      flex-shrink: 1;
      // max-width: 400px;
      display: flex;
      justify-content: center;
      align-items: center;
      // margin: 0 spacing(4);
  
      @include respond-to(md) {
        align-self: flex-start;
        margin-bottom: spacing(4)
      }
  
      .MuiTypography-body1 {
        width: 100%;
        text-align: center;
        font-size: 16px;
      }
    }
  }

  div.actionButtonText {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    max-width: 400px;

    .MuiTypography-body1 {
      font-weight: 500;
      margin: 0;
    }
  }
}