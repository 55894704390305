@import '../../../../styles/toolkit';

ul.scrollableList {
  // height: 100%;
  display: flex;
  
  &.vertical {
    flex-direction: column;
  }

  &.horizontal {
    flex-direction: row;
  }

  &.wrap {
    flex-wrap: wrap;
  }

  list-style: none;
  padding: 0;

  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 0px;

  overflow-y: auto;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none; 

  &::-webkit-scrollbar {
    display: none;
  }
  
  &.noScroll {
    overflow-y: hidden;
  }

  li {
    list-style: none;
    padding: 0;
    margin: 0;
  }
}
