@import "../../../../styles/toolkit";

.adminCreateEstateWithDeceasedFormContainer {
  @include default-form-styling-create-edit();
  
  .inputErrorMessage {
    color: red;
    margin-left: 14px;
    font-size: spacing(1.25);
  }
}