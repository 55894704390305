@font-face {
  font-family: 'GalaxieCopernicus';
  src: url('../../assets/fonts/Copernicus/GalaxieCopernicus-Bold.ttf');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'GalaxieCopernicus';
  src: url('../../assets/fonts/Copernicus/GalaxieCopernicus-BoldItalic.ttf');
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: 'GalaxieCopernicus';
  src: url('../../assets/fonts/Copernicus/GalaxieCopernicus-Book.ttf');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'GalaxieCopernicus';
  src: url('../../assets/fonts/Copernicus/GalaxieCopernicus-BookItalic.ttf');
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: 'GalaxieCopernicus';
  src: url('../../assets/fonts/Copernicus/GalaxieCopernicus-Extrabold.ttf');
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: 'GalaxieCopernicus';
  src: url('../../assets/fonts/Copernicus/GalaxieCopernicus-ExtraboldItalic.ttf');
  font-weight: 800;
  font-style: italic;
}
@font-face {
  font-family: 'GalaxieCopernicus';
  src: url('../../assets/fonts/Copernicus/GalaxieCopernicus-Heavy.ttf');
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: 'GalaxieCopernicus';
  src: url('../../assets/fonts/Copernicus/GalaxieCopernicus-HeavyItalic.ttf');
  font-weight: 900;
  font-style: italic;
}
@font-face {
  font-family: 'GalaxieCopernicus';
  src: url('../../assets/fonts/Copernicus/GalaxieCopernicus-Medium.ttf');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'GalaxieCopernicus';
  src: url('../../assets/fonts/Copernicus/GalaxieCopernicus-MediumItalic.ttf');
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: 'GalaxieCopernicus';
  src: url('../../assets/fonts/Copernicus/GalaxieCopernicus-Semibold.ttf');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'GalaxieCopernicus';
  src: url('../../assets/fonts/Copernicus/GalaxieCopernicus-SemiboldItalic.ttf');
  font-weight: 600;
  font-style: italic;
}