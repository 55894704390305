@import '../../../../styles/toolkit.scss';

.CloseIconContainer {
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: map-get($base-colors, base-4);
  transform: rotate(90deg);
  border-radius: 50%;
}