@font-face {
  font-family: 'Haffer';
  src: url('../../assets/fonts/Haffer/Haffer-Medium.ttf');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Haffer';
  src: url('../../assets/fonts/Haffer/Haffer-Regular.ttf');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Haffer';
  src: url('../../assets/fonts/Haffer/Haffer-SemiBold.ttf');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'HafferSQ';
  src: url('../../assets/fonts/Haffer/HafferSQ-Medium.ttf');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'HafferSQ';
  src: url('../../assets/fonts/Haffer/HafferSQ-Regular.ttf');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'HafferSQ';
  src: url('../../assets/fonts/Haffer/HafferSQ-SemiBold.ttf');
  font-weight: 700;
  font-style: normal;
}