@import '../../../styles/toolkit';

.deceasedInformationContainer {
  height: 100%;
  width: 100%;
  @include flex-column-start-start;

  .welcomeHeaderContainer {
    h1.MuiTypography-h1 {
      font-size: 32px;
      padding-top: spacing(2);
      padding-bottom: spacing(1);
    }

    p.MuiTypography-body1 {
      text-align: left;
    }
  }

  @include respond-to(md) {
    @include flex-start;
    padding: 0;
    height: fit-content;
    width: 390px;
    margin-top: spacing(20);

    .welcomeHeaderContainer {
      padding: 0;

      h1.MuiTypography-h1 {
        font-size: 34px;
      }
    }

    >h6.MuiTypography-h6 {
      display: none;
      visibility: hidden;
    }
  }
}