@import '../../styles/toolkit';

/* This will affect the scrollbar globally */
::-webkit-scrollbar {
  width: 10px; /* width of the entire scrollbar */
}

::-webkit-scrollbar-track {
  background: #f1f1f1; /* color of the tracking area */
  border-radius: 10px; 
}

::-webkit-scrollbar-thumb {
  background: #888; /* color of the scroll thumb */
  border-radius: 10px; /* roundness of the scroll thumb */
}

::-webkit-scrollbar-thumb:hover {
  background: #555; /* color of the scroll thumb on hover */
}

.managementLayoutContainer {
  width: 100%;
  height: 100%;
  display: flex;
  gap: spacing( 6 );
  padding: spacing( 10 ) spacing( 12 );

  .leftBlock {
    max-width: 450px;
    min-width: 400px;
    width: 30%;
    display: flex;
    flex-direction: column;
    gap: spacing( 4 );
    justify-content: center;
    align-items: center;

    .customerView {
      height: 70vh;
      border-radius: 10px;
      // overflow-y: scroll;
    }

    .showCreateFormButton {
      width: 100%;
    }
  }

  .formView {
    width: 40%;
    max-width: 600px;
    min-width: 500px;
    height: 80vh;
    overflow-y: scroll;
    background-color: map-get( $base-colors, base-1 );
    padding: spacing( 4 ) spacing( 6 );
    border-radius: 10px;
  }
}