.crawl {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: black;
  overflow: hidden;
  perspective: 300px;
}

.crawl-content {
  position: relative;
  top: 100%;
  transform-origin: 50% 100%;
  transform: rotateX(20deg) translateZ(-250px);
  animation: crawl 60s linear forwards;
  font-size: 350%;
  text-align: justify;
  color: #00ffff;
}

@keyframes crawl {
  0% { top: 100%; }
  100% { top: -300%; }
}