@import '../../../../styles/toolkit';

.estateComponentSummaryContainer {
  background-color: map-get($base-tints, base-2-40);
  padding: 18px;
  width: 100%;

  .estateComponentDetailsHeadersAndIconContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;

    > .estateComponentDetailsTypeIcon {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      align-self: flex-start;
      height: 40px;
      width: 40px;

      .circleBackground {
        width: 40px;
        height: 40px;
      }

      svg {
        height: 22px;
        width: 22px;
      }
      // border: 2px solid map-get($base-colors, base-4);
      // padding: 7px;
    }

    .estateComponentDetailsHeader {
      margin-bottom: 8px;
    }
  }

  .estateComponentDetailsSummaryContainer {
    display: flex;
    gap: 12px;

    .estimatedValueDetailsContainer,
    .settlementStatusDetailsContainer {
      background-color: map-get($base-tints, base-2-60);
      border-radius: 8px;
      padding: 16px;
      width: 100%;

      > .greyText {
        color: map-get($base-tints, base-4-60);
        font-size: typography-sizing(1.6);
        font-weight: 400;
        margin-bottom: 8px;
        text-overflow: ellipsis;
      }
    }
  }
}