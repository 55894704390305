@import '../../styles/toolkit';

.estateOuterContainer {
  // Base MUI Styles override
  @import '../../styles/typography_overrides.scss';
  animation: slideInRight 0.5s forwards;
  background-color: map-get($background, medium-light);

  .MuiContainer-root {
    max-width: none;
  }

  .adminButtonsContainer {
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    @include respond-to(lg) {
      margin: spacing(3) spacing(13) 0;
    }
  }

  .estateComponentsOuterContainer {
    margin-top: spacing(2);
    margin-bottom: spacing(2);
    padding: 0;

    .MuiCard-root.estateComponentDetails {
      background-color: map-get($base-colors, base-1);
      &:not(:first-child) {
        margin-top: spacing(2);
      }

      .estateComponentDetails__Header {
        border-bottom: 0.3px solid map-get($base-tints, base-4-40);
        background-color: map-get($base-colors, base-1);

        .componentSubTypeIconContainer {
          margin-right: spacing(2);
        }

        >.MuiAccordionSummary-expandIconWrapper >.MuiSvgIcon-root.expandIcon {
          color: map-get($typography, main);
        }
      }

       div.MuiAccordion-region > div.MuiAccordionDetails-root {
        background-color: map-get($base-colors, base-1);
        padding: 0 spacing(2);

        @include respond-to(lg) {
          display: flex;
          flex-wrap: wrap;
          gap: 3.33%;
        }
      }
    }

    @include respond-to(lg) {
      padding-left: spacing(13);
      padding-right: spacing(13);
      
      .MuiCard-root.estateComponentDetails {
        width: 100%;
        

        .MuiAccordionDetails-root {
          @include flex-center-start;
          padding: spacing(3);
          overflow-y: auto;
          width: 100%;
          flex-wrap: wrap;

          .componentMessages {
            padding: spacing(2);
            border: 1px solid lighten(map-get($border, default), 15%);
            border-radius: 4px;
            margin: spacing(2);
            width: 30%;

            .taskMsgContainer {
              padding-top: 0;
            }

            .taskStatusMsgContainer {
              padding-bottom: 0;
            }
          }
        }
      }
    }
  }
}