@import "../../../styles/toolkit";

.resetPasswordContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: spacing(2);

  h1.MuiTypography-h1 {
    color: rgba(0, 0, 0, 0.87);
    font-size: 32px;  
    margin-top: spacing(2);
    margin-bottom: spacing(1);
    width: 361px;
  }

  p.MuiTypography-body1 {
    margin-bottom: spacing(4);
    width: 361px;
  }

  .returnToLoginContainer {
    width: 100%;
    // padding: 0 spacing(2);

    .returnToLoginPageLink {
      width: 100%;
    }
  }

  .resetPasswordFormContainer {
    // padding: 0 spacing(2);
    width: 100%;

    .emailAddressInput,
    .otpCodeInput,
    .hiddenInputContainer {
      margin-bottom: spacing(4);
      width: 100%;
    }

    .emailAddressInput {
      width: 100%;
      // margin-bottom: 50px;
    }

    .buttonsContainer {
      width: 100%;
      padding: 0;
      display: flex;
      flex-direction: column;


      .resetPasswordButton {
        margin-bottom: spacing(2);
        width: 100%;
        height: 48px;
      }
    }
  }
}


@include respond-to(md) {
  .resetPasswordContainer {
    margin-top: spacing(11);
    align-items: flex-start;

    h4.MuiTypography-h4,
    h6.MuiTypography-h6 {
      align-self: flex-start;
      width: fit-content;
      display: inline-block;
    }

    h4.MuiTypography-h4 {
      text-align: left;
      font-size: 34px;
      margin-bottom: spacing(2);
    }

    h6.MuiTypography-h6 {
      height: auto;
      text-align: left;
      width: auto;
      margin-bottom: spacing(6);
    }

    .returnToLoginPageLink {
      max-width: 234px;
      margin-left: 185px;
    }

    .resetPasswordFormContainer {
      max-width: 480px;
      padding: 0;

      div.MuiFormControl-root.hiddenInputContainer.lastInput {
        margin-bottom: 45px;
      }

      .buttonsContainer {
        .resetPasswordButton {
          width: auto;
        }
      }
    }
  }
}