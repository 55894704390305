@import '../../../styles/toolkit';

.notificationsManagementContainer {
  display: flex;
  flex-direction: column;
  gap: spacing(4);
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  max-width: 800px;
  height: 100%;
  padding: spacing(4);

  .notificationListContainer {
    background-color: map-get($base-colors, base-1);

    h4 {
      margin-bottom: spacing(4);
    }
  }
}