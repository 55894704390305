@import '../../../styles/toolkit';

li.fileListItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 56px;
  width: 100%;
  padding: 12px 8px;
  border-bottom: 0.5px solid map-get($base-tints, base-4-40);
  overflow: hidden;
  background-color: map-get($base-tints, base-1-40);

  &:last-child {
    margin-bottom: 0;
  }

  @include respond-to(md) {
    border: none;
    border-bottom: 0.5px solid map-get($base-tints, base-4-40);
    border-radius: 0px;

    &:last-child {
      border-bottom: none;
    }

    &:hover {
      transition: filter 0.3s ease;
      filter: brightness(90%);
    }
  }
  
  p {
    width: 80%;
    text-align: left;
    margin-left: spacing(2);
    word-wrap: break-word;
  }

  button.fileListItemContent {
    background-color: transparent;
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .endCap {
    button {
      background-color: transparent;
    }

    svg {
      width: 24px;
      height: 24px;

      g {
        path {
          stroke: map-get($base-colors, base-4);
        }
      }
    }

    &.fileOptions {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      width: 100%;
      height: 100%;

      .fileDate {
        margin-right: spacing(6);
      }

      svg {
        width: 24px;
        height: 24px;

        g {
          path {
            stroke: map-get($typography, medium);
          }
        }
      }
    }
  }
  
  .viewFileOptionsContainer {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
  }

  .fileProgressBackground {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: map-get($background, medium-light);
    width: 30px;
    height: 30px;
  }

  .fileProgressComplete {
    transition: opacity 0.3s ease-in-out, max-height 0.3s ease-in-out, visibility 0.3s ease-in-out;
    transition-delay: 0.3s;
    opacity: 0;
    max-height: 0; /* Adjust based on your needs */
    visibility: hidden;
    overflow: hidden;
  }
  
  .fileProgressComplete svg {
    transition: transform 0.3s ease-in-out;
    transition-delay: 0.3s;
    transform: scale(0);
  }
  
  .fileProgressComplete.show {
    opacity: 1;
    max-height: 100px;
    visibility: visible;
  }
  
  .fileProgressComplete.show svg {
    transform: scale(1);
  }

  .fileProgressCircle {
    transition: opacity 0.3s ease-in-out, max-height 0.3s ease-in-out, visibility 0.3s ease-in-out;
    opacity: 1;
    max-height: 100px; /* Adjust based on your needs */
    visibility: visible;
    overflow: hidden;
  }
  
  .fileProgressCircle.hide {
    opacity: 0;
    max-height: 0;
    visibility: hidden;
  }

  circle {
    color: map-get($typography, main);
  }
}