@import '../../../styles/toolkit';

svg.EstateViewPieChart {

  text {
    font-family: "Haffer";
    font-size: 13px;

    &.dollarAmount {
      font-size: 30px;
    }

    &.dollarAmountEstateView {
      font-size: 30px;
    }

    &.preText {
      opacity: 0.6;
    }

    &.subText {
      opacity: 0.4;
    }

  }
}