@import "../../styles/toolkit";

.circleIconContainer {

  .circleBackground {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    // background-color: map-get($background, medium);

    svg {
      width: 20px;
      height: 20px;
    }
  }
}