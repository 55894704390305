@import '../../../styles/toolkit';

.recentActivityWrapContainer {
  width: 100%;
  max-width: 1210px;

  .header {
    margin-bottom: spacing(2);
  }

  .content {
    // width: 85%;
    .recentActivityList {
      width: 100%;
      max-height: 425px;
      overflow-y: hidden;
      margin-bottom: spacing(2);

      &.showMore {
        max-height: 100%;
        height: 100%;
      }
    }
  
    .viewMoreActivityButton {
      display: flex;
      justify-content: center;
      width: 100%;

      .buttonText {
        width: 150px;
      }

      @media (max-width: 1500px) {
        justify-content: flex-start;
        padding-left: calc(390px - 75px);
      }

      @media (max-width: 1107px) {
        justify-content: flex-start;
        padding-left: calc(180px - 75px);
      }
    }
  }
}