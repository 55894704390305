@import "../../styles/toolkit";

div.homeViewContainer {
  animation: slideInRight 0.5s forwards;
  background-color: map-get($base-colors, base-2);
  padding: spacing(4) spacing(4) spacing(16) spacing(4);
  width: 100%;
  max-width: 1440px;

  .header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 500px;

    .managementTools {
      display: flex;
      gap: spacing(4);
      margin-bottom: spacing(4);
    }
  }

  .estateChart {
    margin-bottom: spacing(4);
  }

  .actionList {
    margin-bottom: spacing(8);
  }

  .recentActivityWrap {
    width: 100%;
  }

  .keyDatesContainer {
    .keyDatesHeader {
      margin-bottom: spacing(2);
    }
    margin-top: spacing(8);
  }

  .careTeam {
    width: 475px;
    margin: spacing(8) 0 0 0;
  }
}