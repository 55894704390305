@import '../../../styles/toolkit';

.adminToolBarContainer {
  @include flex-center;
  position: fixed;
  top: 0;
  left: 50vw;
  transform: translateX(-50%);
  z-index: 1000;
  gap: spacing(2);
  box-shadow: $large-elevation;
  background-color: map-get($base-colors, base-1);
  padding: spacing(2);
  border-radius: 0 0 8px 8px;

  button {
    background-color: transparent;
  }
}