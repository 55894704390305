@import '../../../styles/toolkit';

.documentsHeaderContainer {
  align-items: center;
  background-color: map-get($base-colors, base-4);
  display: flex;
  justify-content: center;
  padding: 35px 0;

  .documentsHeader {
    color: map-get($base-colors, base-2);
    letter-spacing: 1px;
  }
}
