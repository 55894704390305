@import '../../../styles/toolkit';


li.folderListItemContainer {
  list-style-type: none;

  width: 100%;

  button.folderHeader {
    background-color: map-get($primary, extra-light);
    border-top: 1px solid map-get($border, default);

    box-sizing: border-box;
    width: 100%;
    height: 64px;
    align-items: center;
    justify-content: space-between;
    border-radius: 0;
    padding: 0 spacing(2);
    margin: 0;
    display: flex;

    &:active,
    &:hover,
    &:focus {
      filter: brightness(100%);
    }

    &.overColor {
      background-color: map-get($background, medium);
    }

    @include respond-to(md) {
      &:hover {
        transition: filter 0.3s ease;
        filter: brightness(90%);
      }
    }


    .folderHeaderContainer {
      display: flex;

      p {
        margin-left: spacing(2);
      }
    }

    svg.folderChevronDownIcon {
      transition: transform 0.3s ease-in-out;

      path {
        fill: map-get($typography, medium);
      }

      &.expanded {
        transform: rotate(180deg);
      }
    }

    svg.deleteIcon {
      width: 24px;
      height: 24px;

      path {
        stroke: map-get($typography, medium);
      }
    }

    .endCap {
      display: flex;
      align-items: center;
      justify-content: center;

      .uploadFileContainer {
        display: flex;
        align-items: center;
        justify-content: center;

        .filePickerLabel {
          background-color: transparent;
          border: 1px solid map-get($border, default);
          color: map-get($typography, medium);
          height: 24px;
        }
      }
    }
  }

  .folderFilesDropdown {
    width: 100%;
    height: 0;
    padding: 0;
    height: auto;
    margin: 0 0 spacing(2) 0;

    li.fileListItem {
      box-sizing: border-box;
      background-color: map-get($background, light);
      padding: 0 spacing(1);

      button {
        height: auto;
      }

      :active,
      :hover,
      :focus {
        filter: brightness(100%);
      }
    }
  }
}