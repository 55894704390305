@import "../../styles/toolkit";

.pageLayout {
  @include flex-column;
  background-color: map-get($background, medium-light);
  position: relative;
  &.fixed {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  
  > .pageContent {
    flex-grow: 1;
    -webkit-overflow-scrolling: touch;
    min-height: calc(100vh - 64px);

    > .formModal {
      z-index: 200;
    }
  }

  @include respond-to(md) {
    display: grid;
    grid-template-columns: 250px auto auto;

    grid-template-rows: 64px 1fr 1fr;
    grid-template-areas:
      "header header header"
      "navbar pageContent pageContent"
      "navbar pageContent pageContent";

    > .pageContent {
      grid-area: pageContent;
      background-color: map-get($background, medium-light);
      display: flex;
      flex-direction: column;
      // align-items: center;
      // justify-content: center;
      // display: grid;
      // grid-template-columns: 1fr 1fr; // This creates two columns
      // grid-template-rows: 1fr 1fr; // This creates two rows
    }
  }
  // @include respond-to(lg) {
  //   grid-template-columns: 250px auto auto;
  // }
}