@import '../../../../styles/toolkit.scss';

.notificationsHeader {
  background-color: map-get($base-colors, base-1);
  height: 81px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 2000;
  padding: spacing(4);
}

.emptyStateContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 528px;
  position: absolute;
  top: 57px;
  right: -20px;
  z-index: 1000;
  background-color: map-get($base-colors, base-1);
  border-radius: 8px;
  box-shadow: $large-elevation;
  padding-bottom: spacing(4);

  .emptyStateBell {
    margin: 0 0 spacing(3);
  }

  .emptyStateHeaderText {
    margin-bottom: spacing(3);
  }
}

.notificationsPopupMenuContainer {
  width: 528px;
  max-height: 672px;
  overflow-y: auto;
  background-color: map-get($base-colors, base-1);
  border-radius: 8px;
  box-shadow: $large-elevation;
  padding-bottom: spacing(4);
  position: absolute;
  top: 57px;
  right: -20px;
  z-index: 1000;
  scrollbar-width: thin;
  scroll-snap-stop: always;
}

.notificationsItemListStylesOverride {
  width: auto;
  height: auto;
}

