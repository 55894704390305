// Spacing and offsets
// $root-font-size: max(62.5%, min(1vw, 70%));
$root-font-size: 62.5%;
// $root-font-size: 10px;
$spacing: 8px;
$spacing-half: calc($spacing / 2);
$spacing-double: calc($spacing * 2);

//Elevations
$small-elevation: 0px 1px 3px 0px rgba(0, 0, 0, 0.04), 0px 5px 5px 0px rgba(0, 0, 0, 0.03), 0px 10px 6px 0px rgba(0, 0, 0, 0.02), 0px 18px 7px 0px rgba(0, 0, 0, 0.01), 0px 28px 8px 0px rgba(0, 0, 0, 0.00);
$medium-elevation: 0px 3px 8px 0px rgba(0, 0, 0, 0.08), 0px 14px 14px 0px rgba(0, 0, 0, 0.07), 0px 31px 18px 0px rgba(0, 0, 0, 0.04), 0px 55px 22px 0px rgba(0, 0, 0, 0.01), 0px 85px 24px 0px rgba(0, 0, 0, 0.00);
$large-elevation: 0px 3px 6px 0px rgba(0, 0, 0, 0.12), 0px 12px 12px 0px rgba(0, 0, 0, 0.10), 0px 26px 16px 0px rgba(0, 0, 0, 0.06), 0px 46px 18px 0px rgba(0, 0, 0, 0.02), 0px 72px 20px 0px rgba(0, 0, 0, 0.00);

// Colors
$primary: (
  main: #AB826F,
  extra-light: #EFE9DC,
  light: #E4DAC4,
  medium-light: #EFE9DC,
  medium: #BC9B8C,
  dark: #312B2B,
  highlight: #F6F3EB,
  accent: #FFB5A8,
  alternative: #FAF8F3,
  dark-alternative: #326E5E,
);

$success: (
  main: #237f00,
  light: #53A829
);

$error: (
  main: #C10700,
  light: #FF5C2F
);


$typography: (
  main: map-get( $primary, dark ),
  light: map-get( $primary, light ),
  medium-light: map-get( $primary, main),
  medium: map-get( $primary, medium ),
  accent: map-get( $primary, accent ),
  alternative: map-get( $primary, dark-alternative ),
  error: map-get( $error, main),
);

$background: (
  main: map-get( $primary, main ),
  light: map-get( $primary, highlight ),
  medium-light: map-get( $primary, light ),
  medium: map-get( $primary, medium),
  dark: map-get( $primary, dark ),
  accent: map-get( $primary, accent ),
  alternative: map-get( $primary, alternative ),
  dark-alternative: map-get( $primary, dark-alternative ),
);

$border: (
  default: lighten(map-get( $primary, dark), 50%),
  light: map-get( $primary, light ),
  medium: map-get( $primary, medium ),
);

$status: (
  complete: map-get( $success, main ),
  attention: map-get( $error, main ),
);

$radius: (
  default: $spacing-half,
  double: $spacing
);

$elevation: (
  button: '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
  footer: '0 -2px 8px 0 rgb(51 51 51 / 13%)'
);

$easing: (
  easeInOut: cubic-bezier(0.4, 0, 0.2, 1)
);

$transitions: (
  default: "250ms #{map-get( $easing, easeInOut )} 0ms"
);
