@import '../../../styles/toolkit';

.documentsHeaderContainer {
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 45px 0;

  .documentsHeader {
    letter-spacing: 1px;
  }
}