@import '../../styles/toolkit.scss';

.mobileContainer {
  height: 100vh;
  overflow: auto;
}

.notificationCardListContainer {
  width: 100%;
  height: 100%;
}

.emptyStateContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;

  .emptyStateBell {
    margin: spacing(4) 0 spacing(3);
  }

  .emptyStateHeaderText {
    margin-bottom: spacing(3);
  }
}