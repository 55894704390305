@import "../../../../styles/toolkit";

.moveDocumentsFormContainer {
  @include default-form-styling-create-edit();
  min-width: 500px;

  .selectedFileList {
    display: flex;
    flex-direction: column;

    .selectedListHeader {
      margin-bottom: spacing(2);
    }

    .fileToMove {
      margin: spacing(1) 0;
    }

  }

  .buttonsContainer {
    display: flex;
    justify-content: flex-end;
    gap: spacing(1);

    .loadingSpinner {
      // width: 20px;
      margin-left: $spacing(1);
    }
  }
}