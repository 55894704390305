@import '../../styles/toolkit';

#estateComponentDetailsMobile {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  overflow: hidden;

  
  >.summaryHeadingContainer {
    width: 100%;
    margin-top: 64px;


    @include elevation(3);

    .listHeading {
      background-color: map-get($base-tints, base-2-80);
      padding: 24px 16px;
    }
  }

  >.cardListContainer {
    .scrollableList {
      height: calc(100vh - 64px - 275px);
      padding-top: spacing(2);
    }
  }
}