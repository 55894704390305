// App Wide
@import 'fonts/Copernicus';
@import 'fonts/Haffer';
@import 'fonts/old';
@import 'toolkit';

// Set Root Styles
html {
  font-size: 100%;
  background-color: map-get($background, medium-light);
}

// Default Input Styles
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
  -webkit-text-fill-color: map-get($primary, light) !important;
  -webkit-box-shadow: 0 0 0 100px map-get($background, dark) inset !important;
}

.layoutModal .MuiDialog-paper,
.layoutModal .MuiDialog-container {
  max-width: none;
}

.MuiFormLabel-root.Mui-error {
  &.light {
    color: map-get($error, light);
  }

  &.dark {
    color: map-get($error, main);
  }
}

// Remove default border states with errors, and normal mui inputs
.MuiInput-underline:after,
.MuiInput-underline:before,
.MuiInput-underline:hover:not(.Mui-disabled):before,
.MuiInput-underline.Mui-error:after,
.MuiInput-underline.Mui-error:before,
.MuiInput-underline.Mui-error:hover:not(.Mui-disabled):before {
  border-bottom: none;
}

.MuiFormControl-root {
  // .MuiInputBase-root {
  //   color: map-get($primary, light) !important;
  //   &:focus {
  //     color: map-get($primary, light) !important;
  //   }
  // }

  &.light {
    color: map-get($primary, light) !important;

    .MuiInputBase-root {
      color: map-get($primary, light) !important;
      border-bottom: 1px solid map-get($primary, light);

      svg {
        color: map-get($primary, light) !important;
      }

      &:focus {
        color: map-get($primary, light) !important;
      }
    }

    .MuiInputBase-input {
      color: map-get($primary, light) !important;

      &:focus {
        color: map-get($primary, light) !important;
      }
    }

    .MuiSvgIcon-root.css-i4bv87-MuiSvgIcon-root {
      color: map-get($primary, light) !important;

      &:focus {
        color: map-get($primary, light) !important;
      }
    }
  }

  &.error.dark {
    .MuiInputBase-root {
      border-bottom: 1px solid map-get($error, main);
    }
  }

  &.error.light {
    .MuiInputBase-root {
      border-bottom: 1px solid map-get($error, light);
    }
  }
}


// Default Typography Styles
h1.MuiTypography-root.MuiTypography-h1,
h2.MuiTypography-root.MuiTypography-h2,
h3.MuiTypography-root.MuiTypography-h3,
h4.MuiTypography-root.MuiTypography-h4,
h5.MuiTypography-root.MuiTypography-h5,
h6.MuiTypography-root.MuiTypography-h6 {
  font-family: 'GalaxieCopernicus';
  font-weight: normal;
  font-style: normal;

  &.light {
    color: map-get($typography, light);
  }
}

h6.MuiTypography-root.MuiTypography-h6 {
  size: spacing(1);
}

// null base styles form library
p {
  display: block;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

p.MuiTypography-root.MuiTypography-body1 {
  font-family: 'Haffer';
  color: map-get($typography, main);

  &.light {
    color: map-get($typography, light);
  }
}

// Default Button Styles
// button.MuiButtonBase-root.MuiButton-root {
button {
  font-family: 'Haffer';
  border: none;
  color: map-get($base-colors, base-4); // set base color explicitly to override ios defaults

  &:hover,
  &:active,
  &:focus {
    filter: brightness(90%);
    cursor: pointer;
  }
}

a {

  &:hover,
  &:active,
  &:focus {
    filter: brightness(80%);
  }
}

// Default Card Styles
.MuiCard-root.css-1d7qqce-MuiPaper-root-MuiCard-root {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.04), 0px 1px 1px 0px rgba(0, 0, 0, 0.04), 0px 3px 3px 0px rgba(0, 0, 0, 0.03), 0px 6px 3px 0px rgba(0, 0, 0, 0.02), 0px 10px 4px 0px rgba(0, 0, 0, 0.01), 0px 16px 4px 0px rgba(0, 0, 0, 0.00);
}