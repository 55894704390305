@import '../../styles/toolkit';

.MuiAccordionSummary-content {
  display: flex;
  flex-direction: row;
  align-items: center;

  div.MuiAccordion-region > .MuiAccordionDetails-root {
    margin: 0;
    padding: 0;
  }

  .circleIconContainer > .componentSubTypeIconBackground {
    height: 40px;
    width: 40px;
    background-color: map-get($base-colors, base-2);
  }
}