@import '../../styles/toolkit';

div.statusTagContainer {
  @include flex-center;
  height: spacing(4);
  border-radius: 100px;
  padding: spacing(0.0) spacing(1);
  background-color: map-get($background, medium);
  color: map-get($typography, main);
  
  &.completed {
    background-color: map-get($status, complete );
  }

  &.attention {
    background-color: map-get($status, attention );

  }
  
  .MuiTypography-body1.statusText {

    &.completed {
      color: map-get($status, complete);
    }

    &.attention {
      color: map-get($status, attention);
    }
  }
}