@import "../../../../styles/toolkit";

.createOneActionCardFormContainer {
  @include default-form-styling-create-edit();

  a.createFolderLink {
    width: fit-content;
  }

  p.downloadMessage {
    max-width: 500px;
    color: map-get($typography, medium);
  }
}