@import '../../styles/toolkit';

// .layoutModal .MuiDialogContent-root.layoutModalContent {
//   width: 60vw;
// }

.selectUserContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
  min-width: 30vw;
  min-height: 80vh;

  .selectUserHeaderContainer {
    @include flex-column-center;
    margin-bottom: spacing(1);

    .selectUserButtonContainer {
      @include flex-center;
      gap: spacing(2);

      .modifyUsersButton {
        padding: 0 spacing(2);
        color: map-get($typography, light);
      }
    }
  }

  hr {
    width: 100%;
  }

  h4 {
    margin-bottom: spacing(2);
  }

  .userSelectStack {
    width: 100%;
    
    .userSelectCard {
      @include flex-center;
      width: 100%;
      justify-content: space-between;
      padding: spacing(2);
      margin-bottom: spacing(2);

      p {
        font-size: 20px;
      }
  
      .userSelectButtonContainer {
        @include flex-center;

        button {
          margin-left: spacing(2);
        }
      }
    }
  }
  
}