@import "../../../../styles/toolkit";

.renameDocumentFormContainer {
  // @include default-form-styling-create-edit();
  width: 100%;
  padding: 0 spacing(2);

  @include respond-to(md) {
    display: flex;
    flex-direction: column;
    // align-items: center;
    justify-content: center;
    width: 650px;
    // height: 300px;
    padding: 0;
  }

  .renameDocumentFormHeader {
    display: none;
    position: absolute;
    top: spacing(4); /* Adjust for header */
    left: 50%; /* Adjust for side nav */
    transform: translate( -50%);
    font-weight: 500;

    @include respond-to(md) {
      display: block;
    }
  }


  .formContainer {
    padding: 0;
    width: 100%;

    @include respond-to(md) {
      padding-top: spacing(6);
    }

    .fieldInput {
      width: 100%;
      margin-bottom: spacing(4);
    }

  }

  .buttonsContainer {
    display: flex;
    justify-content: space-between;
    margin-bottom: spacing(4);

    @include respond-to(md) {
      justify-content: flex-end;
    }

    button {
      width: 160px;

      &:first-child {
        margin-right: spacing(2);
      }

      &.submitFormButton {
        background-color: map-get($background, dark);
        color: map-get($typography, light);
      }
    }
  }
}