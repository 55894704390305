@import '../../../../styles/toolkit.scss';

.notificationBellAsPopupMenuContainer {
  width: 48px;
  height: 100%;

  .unseenNotificationsMarker {
    position: absolute;
    bottom: 12px;
    right: 14px;
    width: 10px;
    height: 10px;
    background-color: map-get($alert-colors, alert-1);
    border-radius: 50%;
  }

  button.bellButton {
    border-radius: 0;
    background-color: transparent;
  }
}