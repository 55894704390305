@import '../../styles/toolkit';

#careTeamMobileContent {
  padding: spacing(3);

  img {
    width: 100%;
  }

  .howCanWeHelpText, .noRequestText {
    text-align: center;
  }

  .howCanWeHelpText {
    margin-bottom: spacing(3);
  }

  .noRequestText {
    margin-bottom: spacing(7);
  }
}