@import '../../../styles/toolkit';

.backNavHeaderContainer {
  width: 100%;
  height: 100%;

  &.fixed {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    // height: 100%;
  }


  height: 64px;
  width: 100%;
  z-index: 900;
  padding: spacing(2);
  display: flex;
  gap: spacing(4);
  align-items: center;
  justify-content: center;
  background-color: map-get($background, dark);

  color: map-get($base-tints, base-3-80);

  .backButton {
    position: absolute;
    left: 16px;
  }

}