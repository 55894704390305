@import '../../styles/toolkit';

.layoutModal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); 
  z-index: 1;
    
    // @include respond-to(md) {
    //   width: 60vw;
    // }
  
}

.layoutModalContent {
  position: absolute;
  top: calc(50% + 30px); /* Adjust for header */
  left: calc(50% + 100px); /* Adjust for side nav */
  transform: translate(-50%, -50%);
  margin: 0;
  background-color: map-get($background, light);
  border: none; /* Remove default border */
  box-shadow: none; /* Remove default shadow */
  color: inherit; /* Inherit color from parent */
  width: fit-content;
  max-height: 70vh;
  border-radius: 5px;
  
  overflow-y: auto;

  .closeButton {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 1.5em;
    cursor: pointer;
  }

  &.bottom {
    background-color: map-get($background, medium-light);
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    height: 30%;
  }

}