@import '../../../../styles/toolkit';

.chartContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10%;
  padding: spacing(3) spacing(3) spacing(5) spacing(3);
  // max-width: 400px;

  @include respond-to(xs) {
    gap: 20%;
  }

  .chartSummary {
    @include flex-column;
    align-items: flex-start;
    justify-content: center;
    .estateValueDescriptor {
      color: map-get($base-tints, base-4-80);
      padding-bottom: spacing(1);
    }

    .estateDollarValueText {
      margin-bottom: spacing(3);
    }

    .estateLinkButton {
      width: 150px;
      height: fit-content;

      .buttonContent {
        display: flex;
        align-items: center;
        padding: 5px 7px 5px 10px;

        p {
          margin-right: spacing(1);
          font-weight: 600;
          font-size: 12px;
        }

        .svgRightArrowSvgIcon {
          height: 12px;
          width: 12px;
        }
      }
    }
  }

  .pieChart {
    display: flex;
    align-items: center;
    justify-content: center;
  }

}