@import "../../styles/toolkit";

div.actionListContainer {
  width: 100%;
  // min-height: 100vh;

  .actionListHeader {
    color: map-get($typography, main );
    margin: spacing(2) 0;
    text-align: left;

    // @include respond-to(lg) {
    //   font-size: 24px;
    //   // height: spacing(10);
    //   line-height: spacing(4);
    //   padding: 0;
    //   margin: 0 0 spacing(2) 0;
    // }
  }

  .actionListStack {
    margin-bottom: spacing(4);
  }

  .buttonContainer {
    width: 100%;
    display: flex;
    justify-content: flex-start;

    .addIcon {
      margin: spacing(1);
    }
  }

  >.actionListActionCard {
    width: 100%;
  }

  div.actionListEmptyState {
    @include flex-column-center;
    padding: spacing(4) spacing(4) 0 spacing(4);
    margin: spacing(2) 0 spacing(4) 0;
    border: 1px dashed map-get($primary, dark);
    border-radius: 8px;

    .emptyStateMessage{
      text-align: center;
      color: map-get($typography, main);
      margin-bottom: spacing(4);
    }
  }
}