@import "../../styles/toolkit";

#actionDetailsDesktop {
  display: grid;
  grid-template-columns: 1fr 1fr; // This creates two columns
  grid-template-rows: auto 1fr; // This creates two rows

  >.actionDetailsDesktopHeader {
    grid-row: 1;
    grid-column: 1 / span 2;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: spacing(4) spacing(16);
    border-bottom: 1px solid map-get($border, default);
    margin-bottom: spacing(6);

    h4 {
      font-size: 32px;
      font-style: normal;
      font-weight: 500;
      line-height: 120%; /* 38.4px */
      letter-spacing: -0.32px;
    }

    svg {
      path {
        stroke: map-get($typography, main);
      }
    }
  }

  >.actionDetailsContainer {
    grid-row: 2;
    grid-column: 1;

    animation: slideInRight 0.5s forwards;
    position: relative;
    background-color: map-get($background, light);
    height: fit-content;
    overflow-wrap: normal; 
    width: 600px;
    border-radius: 4px;
    margin: 0 auto;
  
    .actionDetailsStatusContainer {
      // max-width: 400px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: spacing(2);
      margin: spacing(4) spacing(2) spacing(2) spacing(2);
  
      
      svg {
        width: 80px;
        height: 80px;
        margin: 0 spacing(2) 0 0;
      }
  
      >.statusNotificationContent {
        p.MuiTypography-root.MuiTypography-body1.statusNotificationTitle {
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 150%; /* 24px */
          letter-spacing: 0.16px;
  
          &.completed {
            color: map-get($success, main);
          }
        }
  
        p.MuiTypography-root.MuiTypography-body1.statusNotificationMessage {
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%;
          letter-spacing: 0.16px;
  
  
          &.completed {
            color: map-get($success, main);
          }
        }
      }
    }
    
    .actionDetailsHeader {    
      >.actionDetailsTitle {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: spacing(6) spacing(4);
        width: auto;
  
        >.circleIconContainer {
          margin-bottom: spacing(2);
  
          >.circleBackground {
            width: 64px;
            height: 64px;
            svg {
              color: map-get($border, default );
              width: 40px;
              height: 40px;
            }
          }
        }
  
  
        >.MuiTypography-body1 {
          margin-left: spacing(1);
          margin-bottom: spacing(2);
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%; /* 24px */
          letter-spacing: 0.16px;
        }
  
        >.MuiTypography-h4 {
          text-align: center;
          font-size: 20px;
          font-style: normal;
          font-weight: 400;
          line-height: 144%; /* 28.8px */
          letter-spacing: -0.2px;
          // max-width: 300px;
          margin: 0 spacing(4);
        }
  
      }
    }
  
    .actionDetailsDivider {
      background-color: map-get($border, default);
      height: 1px;
      width: 100%;
      margin: spacing(3) 0
    }
  
  
    .actionDetailsBody {
      @include flex-column-center;
      width: 100%;
      padding: 0 0 spacing(8) 0;
  
      padding: 0;
  
      .actionContent {
        @include flex-column-center;
        margin-bottom: spacing(5);
        width: 100%;
        
        .actionDetailsDescription {
          margin: 0 spacing(4);
        }
  
        .appointmentDetailsBodyText  {
          margin-block-start: 0;
          margin-block-end: 0;
          margin-inline-start: 0px;
          margin-inline-end: 0px;
          margin-left: spacing(6);
          // margin-right: spacing(6);
        }
  
        .actionDetailsDescription {
          margin-bottom: spacing(4);
        }
  
        .actionDetailsButtonContainer {
          width: 100%;
          padding: 0 spacing(4);
          display: flex;
          align-items: center;
          justify-content: center;
        }
  
        button.actionDetailsButton {
          text-align: center;
          width: auto;
          margin: 0 0 spacing(4) 0;
          padding: spacing(2) spacing(4);
        }
  
        a.actionDetailsButton {
          width: 100%;
          margin: 0 0 spacing(4) 0;
          .actionButtonText {
            @include flex-center;
          }
        }
      }
      
      .actionDetailsBodyHeader {
        background-color: map-get($background, medium-light);
        border-radius: 25px;
        text-align: center;
        color: map-get($typography, medium-light);
        width: fit-content;
        padding: spacing(.5) spacing(2);
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 150%; 
        letter-spacing: 0.16px;
        margin: 0 0 spacing(2) 0;
      }
  
      .actionDetailsNeedHelpContainer {
        display: flex;
  
        a.cardLinkWrapper {
          text-decoration: none;
        }
  
        .actionDetailsNeedHelp {
          @include flex-center();
          padding: spacing(1);
          margin: spacing(2);
          max-width: 360px;
    
          @include respond-to(lg) {
            @include flex-column-center;
            margin: 0 spacing(1);
            padding: spacing(4);
            max-width: 500px;
          }
    
          .cardBody {
            @include flex-center;
            
            @include respond-to(lg) {
              margin-bottom: spacing(2);
            }
  
            .MuiAvatar-root {
              padding: spacing(1);
              width: 60px;
              height: 60px;
      
              @include respond-to(lg) {
                height: 200px;
                width: 200px;
                margin-right: spacing(2);
              }
            }
        
            .MuiTypography-body1 {
              span {
                font-weight: 700;
              }
            }
          }
  
          .cardFooter {
            display: none;
  
            @include respond-to(lg) {
              display: block;
            }
          }
        }
      }
    }
  }

  >.careTeamCardContainer {
    grid-row: 2;
    grid-column: 2;
    width: 480px;
    margin: 0 0 0 spacing(4);
  }
}