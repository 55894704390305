@import '../../../../styles/toolkit';

.componentDetailCardContainer {
  display: flex;
  align-items: center;

  .componentDetailDate {
    margin-right: spacing(2);

    p {
      color: map-get($base-tints, base-4-60);
      font-weight: 500;
      width: 50px;
    }
  }

  .componentDetailCard,
  .componentDetailCard button {
    max-width: 530px;

    &.completed {
      background-color: map-get($base-tints, base-2-40);
    }

    .cardTitle {
      display: flex;
      align-items: center;
      margin-bottom: spacing(2);

      p {
        font-weight: 500;
        margin-left: spacing(2);
        text-align: left;
      }

      svg {
        box-sizing: border-box;
        width: 32px;
        height: 32px;
        min-width: 32px; // will shrink if this isn't here
      }

      img {
        box-sizing: border-box;
        border-radius: 50%;
        width: 32px;
        height: 32px;
        min-width: 32px; // will shrink if this isn't here
      }
    }

    .cardBody {
      margin-bottom: spacing(2);
      display: none;
      text-align: left;

      &.expanded {
        display: block;
      }

      .wordWrap {
        word-wrap: break-word;
      }

      p {
        word-break: break-word;
        text-align: left;
      }
    }

    .cardFooter {
      display: flex;
      align-items: center;
      width: 100%;

      &.space-between {
        justify-content: space-between;
      }

      &.flex-end {
        justify-content: flex-end;
      }

      p {
        font-weight: 600;
      }

      p.userName {
        color: map-get($base-tints, base-3-60);
      }

    }
  }

}