@import "../../../styles/toolkit";

div.documentSignContainer {
  @include flex-column-center;
  max-width: 360px;

  @include respond-to(md) {
    align-items: flex-start;
    max-width: 650px;
  }

  .MuiTypography-body1.documentSignBodyText {

    width: 100%;
    text-align: start;
    margin-bottom: spacing(2);
  }

  a.solid.documentSignButton {
    width: 100%;
    max-width: 400px;
    margin-bottom: spacing(2);
  }

  button.notificationButton {
    width: 100%;
    margin-bottom: spacing(2);
    max-width: 300px;

    >p.notificationButtonText {
      color: map-get($typography, dark);
    }
  }

  div.signDocumentButtonText {
    @include flex-center;
    width: 100%;

    svg {
      margin-right: spacing(1);
    }

    .MuiTypography-body1 {
      margin: 0;
    }
  }
}