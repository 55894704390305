@import '../../../../styles/toolkit';

.notificationBellAsLinkContainer {
  .unseenNotificationsMarker {
    position: absolute;
    bottom: 12px;
    right: 6px;
    width: 10px;
    height: 10px;
    background-color: map-get($alert-colors, alert-1);
    border-radius: 50%;
  }

  a.notificationBellAsLink {
    padding: 0;
    margin-right: 26px;
  }
}