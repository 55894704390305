@import '../../styles/toolkit';

.statusIconContainer {
  width: 40px;

  &.attention {
    svg {
      path {
        stroke: map-get($status, attention);
      }
      rect {
        stroke: map-get($status, attention );
      }
    }
  }
}