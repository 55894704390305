@import '../../../styles/toolkit';

div.resetNewUserPasswordContainer {
  @include flex-column-center;
  height: 100%;
  width: 100%;
  min-height: 600px;
  justify-content: flex-start;
  align-items: flex-start;

  .welcomeHeaderContainer {
    padding: spacing(2);

    h1.MuiTypography-h1 {
      font-size: 32px;
      padding-top: spacing(2);
      padding-bottom: spacing(1);
    }

    p.MuiTypography-body1 {
      // padding: spacing(4) spacing(2);
      text-align: left;
    }
  }

  form {
    padding: 0 spacing(2);
    width: 100%;

    #newPassword .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
      border: none;
    }

    div.fieldWithMargin {
      width: 100%;

      div.passwordInput {
        width: 100%;
        color: map-get($typography, light);
      }

      div.inputErrorMessage {
        color: map-get($error, light);
      }

      #newPassword:focus,
      #newPassword:focus,
      #newPasswordConfirm,
      #newPasswordConfirm:focus {
        border-bottom-color: map-get($primary, main);
        transition: none;
      }

      // remove underline on hover
      .MuiInput-underline:before,
      .MuiInput-underline:after {
        transition: none;
      }

      .MuiInput-underline:after,
      .MuiInput-underline:hover:not(.Mui-disabled):before {
        border-bottom: none;
      }

      .MuiFormLabel-root.Mui-error {
        color: map-get($error, light);
      }

      &.eulaField {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        min-height: 72px;
        margin-top: spacing(4);

        >.row {
          display: flex;
          flex-direction: row;
          align-items: center;

          span.acceptedEula {
            padding: 0;

            &.normal>svg.MuiSvgIcon-root {
              fill: map-get($typography, light) !important;
            }

            &.error>svg.MuiSvgIcon-root {
              fill: map-get($typography, main) !important;
            }
          }

          .eulaCheckboxLabel {
            margin-left: spacing(1);

            >.serviceAgreementText {
              color: map-get($typography, light);
              font-size: 12px;

              >a {
                font-size: 11px;
                color: map-get($typography, light);
                text-decoration-color: map-get($typography, light);
                text-underline-offset: 2px;
              }
            }
          }
        }

        .inputErrorMessage {
          padding-top: spacing(1);
          padding-left: spacing(4);
          color: map-get($error, main);
        }
      }

      &.eulaField.error {
        background-color: map-get($background, medium-light);
        border-radius: 8px;
        border: 1px solid black;
        // padding: spacing(2) spacing(2) spacing(2) 0;
        padding: spacing(2);

        >.row>label.eulaCheckboxLabel>p.serviceAgreementText {
          color: map-get($typography, main);

          >a {
            color: map-get($typography, main);
            text-decoration-color: map-get($typography, main);
          }
        }
      }
    }

    div.MuiFormControl-root.hiddenInputContainer {
      width: 100%;

      &:not(:first-child) {
        margin-top: spacing(4);
      }
    }

    div.buttonsContainer {
      @include flex-column-center;
      width: 100%;
      justify-content: center;
      padding: 0;

      >button.saveNewPasswordButton {
        width: 100%;
        text-transform: none;
        border-radius: 24px;
        height: 48px;
        font-size: 16px;
        margin-top: spacing(2);
        margin-bottom: spacing(3);
        background-color: map-get($background, main)
      }
    }
  }

  // desktop breakpoint
  @include respond-to(lg) {
    margin-top: spacing(20);
    padding: 0;
    height: fit-content;
    max-width: 390px;

    .welcomeHeaderContainer {
      padding: 0;

      .welcomeMessage {
        display: none;
      }

      h1.MuiTypography-h1 {
        font-size: 34px;
      }

      >h6.MuiTypography-h6 {
        display: none;
        visibility: hidden;
      }
    }

    form {
      justify-content: flex-start;
      padding: spacing(4) 0;
      margin-bottom: spacing(2);

      div.fieldWithMargin {
        margin-bottom: spacing(2);

        >.inputErrorMessage {
          color: map-get($error, main);

          &.hidden {
            display: none;
            visibility: hidden;
          }
        }
      }

      div.buttonsContainer {
        >button.saveNewPasswordButton {
          width: 150px;
          align-self: flex-start;
          margin: 0;
        }

        p {
          max-width: 400px;
          text-align: left;
          align-self: flex-start;

          &.MuiTypography-body1 {
            font-size: 14px;
          }
        }
      }
    }
  }
}