// Typography
@mixin UI1() {
  font-family: 'Haffer';
  margin: 0;
}

@mixin UI2() {
  font-family: 'Copernicus';
  margin: 0;
}

// Visibility Toggles
@mixin invisible() {
  display: none;
  visibility: hidden;
}

// Element resize
@mixin resize($width, $height: $width) {
  width: $width;
  height: $height;
}

// Flex
@mixin flex-center() {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin flex-center-spaced() {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@mixin flex-center-start() {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

@mixin flex-center-end() {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

@mixin flex-start() {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

@mixin flex-start-center() {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

@mixin flex-start-spaced() {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

@mixin flex-end() {
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

@mixin flex-end-spaced() {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

@mixin flex-column() {
  display: flex;
  flex-direction: column;
}

@mixin flex-column-center() {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@mixin flex-column-start() {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

@mixin flex-column-start-start() {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

// Border Radius
@mixin border-top-radius($radius: 0) {
  border-top-right-radius: $radius;
  border-top-left-radius: $radius;
}

@mixin border-bottom-radius($radius: 0) {
  border-bottom-right-radius: $radius;
  border-bottom-left-radius: $radius;
}

@mixin border-left-radius($radius: 0) {
  border-top-left-radius: $radius;
  border-bottom-left-radius: $radius;
}

@mixin border-right-radius($radius: 0) {
  border-top-right-radius: $radius;
  border-bottom-right-radius: $radius;
}

@mixin ellipsis {
  overflow: hidden;

  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin add-sibling-comma($margin-right: 0, $margin-left: 0) {
  &:not(:last-of-type) {
    &:after {
      display: inline-block;
      content: ', ';

      margin: 0 $margin-right 0 $margin-left;
    }
  }
}

@mixin default-form-styling-create-edit() {
  @include flex-column-center();
  justify-content: flex-start;
  align-items: flex-start;
  padding: spacing(3) 0;

  input:-webkit-autofill,
  input:-webkit-autofill:hover, 
  input:-webkit-autofill:focus {
    -webkit-text-fill-color: map-get($primary, dark) !important;
    -webkit-box-shadow: 0 0 0 100px map-get($background, light) inset !important;
  }

  h4 {
    text-align: left;
    margin-bottom: spacing(3);
    padding-left: spacing(2);
  }

  form {
    width: 100%;

    h5 {
      text-align: center;
      font-size: spacing(3);
      margin-bottom: spacing(1);
    }

    hr {
      margin-bottom: spacing(2);
    }

    .formContainer {
      padding: 0;
      width: 100%;

      .formSection {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: spacing(2);

        >h5 {
          grid-column: span 2;
        }

        .inputWrapper {
          margin-bottom: spacing(2);

          label {
            color: map-get($typography, main);
          }

          &.dropdownInputWrapper {
            grid-column: span 1;
          }

          &.textInputWrapper,
          &.numberInputWrapper {
            grid-column: span 2;
            grid-row: span 2;

            .fieldInput {
              width: 100%;
            }
          }

          &.booleanInputWrapper {
            display: flex;
            align-items: center;

            p:nth-of-type(2) {
              margin-left: spacing(2);
            }
          }

          div.inputErrorMessage {
            // margin-bottom: spacing(2);
            color: map-get($alert-colors, alert-1);
          }
        }
      }

      >.formSection {
        grid-column: span 2;
      }
    }

    .buttonsContainer {
      display: flex;
      justify-content: flex-end;
      margin-bottom: spacing(4);

      button {
        margin-left: spacing(2);
      }
    }
  }
}

@mixin default-form-styling-delete() {
  max-width: 800px;
  padding: spacing(4);
  h2 {
    font-size: 32px;
    margin-bottom: spacing(2);
    text-align: center;
  }

  .actionButtonContainer {
    display: flex;
    justify-content: center;

    button {
      margin-left: spacing(2);
      font-size: 24px;
    }
  }
}

@mixin authentication-form-styling() {
  width: 100%;
  display: flex;
  justify-content: left;

  form {
    padding: 0 spacing(2);
    width: 100%;

    .formContainer {
      width: 100%;
      padding: 0;
      .formSection {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: left;
        padding: 0;
        .inputWrapper {
          width: 100%;
          margin-bottom: spacing(2);
          .MuiFormLabel-root.Mui-error {
            color: map-get($error, light);
          }

          .fieldInput {
            width: 100%;
          }
      
          input {
            width: 100%;
            color: map-get($typography, light);
          }
      
          input:focus {
            border-bottom-color: map-get($primary, main);
            transition: none;
          }
      
          .MuiInput-underline:before, .MuiInput-underline:after {
            transition: none;
          }
          .MuiInput-underline:after, .MuiInput-underline:hover:not(.Mui-disabled):before {
            border-bottom: none;
          }
      
          .MuiIconButton-root svg {
            color: map-get($typography, light);
          }
          
          div.inputErrorMessage {
            // margin-bottom: spacing(2);
            color: map-get($alert-colors, alert-1);
          }
      
          div.fieldWithMargin {
            margin-bottom: spacing(2);
          }
      
          div.MuiFormControl-root.hiddenInputContainer {
            width: 100%;
      
            &:not(:first-child) {
              margin-top: spacing(4);
            }
          }
        }
      }
    }

    div.buttonsContainer {
      @include flex-center();
      gap: spacing(2);
      width: 100%;
      justify-content: flex-start;
      padding: 0;

      .serviceAgreementText {
        margin-bottom: spacing(2);
        font-size: 12px;
      }

      >button.submitFormButton {
        width: 100%;
        margin: spacing(2) 0 spacing(2) 0;
      }

      >button.signInButton,
      >button.signUpButton {
        width: 100%;
        margin-bottom: spacing(2);
      }

      >button.signInButton {
        background-color: map-get($primary, main);
        color: map-get($typography, light);
      }

      >button.signUpButton {
        background-color: map-get($primary, medium);
        color: map-get($typography, light);
      }

      >button.forgotPasswordButton {
        background-color: transparent;
        color: map-get($typography, light);
        font-size: 12px;
      }
    }
  }

  @include respond-to(md) {
    form {
      justify-content: flex-start;
      padding: spacing(4) 0;

      div.buttonsContainer {
        >button.submitFormButton,
        >button.signInButton {
          width: 100px;
          align-self: flex-start;
        }

        p {
          max-width: 400px;
          text-align: left;
          align-self: flex-start;

          &.MuiTypography-body1 {
            font-size: 14px;
          }
        }
      }
    }
  }
}

// Elevations
@mixin elevation($level: 0) {
  @if $level == 0 {
    box-shadow: none;
    z-index: $level;
  } @else if $level > 0 and $level < 5 {
    box-shadow: $small-elevation;
    z-index: $level;
  } @else if $level > 4 and $level < 9 {
    box-shadow: $medium-elevation;
    z-index: $level;
  } @else {
    box-shadow: $large-elevation;
    z-index: $level;
  }
}