@font-face {
  font-family: 'Castoro';
  src: url('../../assets/fonts/old/Castoro-Italic.ttf');
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: 'Castoro';
  src: url('../../assets/fonts/old/Castoro-Regular.ttf');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Karla';
  src: url('../../assets/fonts/old/Karla-Italic-VariableFont_wght.ttf');
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: 'Karla';
  src: url('../../assets/fonts/old/Karla-VariableFont_wght.ttf');
  font-weight: normal;
  font-style: normal;
}