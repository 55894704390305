// Breakpoints
$xs: 330px;
$sm: 600px;
$md: 800px;
$lg: 1024px;
$xl: 1920px;

@mixin respond-to($media) {
  @if $media ==xs {
    @media only screen and (min-width: $xs) {
      @content;
    }
  }

  @else if $media ==sm {
    @media only screen and (min-width: $sm) {
      @content;
    }
  }

  @else if $media ==md {
    @media only screen and (min-width: $md) {
      @content;
    }
  }

  @else if $media ==lg {
    @media only screen and (min-width: $lg) {
      @content;
    }
  }

  @else if $media ==xl {
    @media only screen and (min-width: $xl) {
      @content;
    }
  }
}