@import "../../../../styles/toolkit";

.updateOneActionCardFormContainer {
  @include default-form-styling-create-edit();
}

.currentFolderContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 20px;
}