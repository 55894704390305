@import '../../../../styles/toolkit';

.keyDateCardContainer {
  width: 100%;
  height: 134px;
  background-color: map-get($base-colors, base-1);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0;

  .date {
    width: 80px;
    min-width: 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    border-right: 1px solid map-get($base-colors, base-2);
  }

  .title {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: spacing(2);
  }
}