@import "../../../../styles/toolkit";

button.outline,
button.solid {
  @include flex-center;
  border-radius: 100px;
  font-family: Haffer;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  text-transform: none;
  line-height: 26px;
  height: 48px;
  padding: spacing(.5) spacing(1);
  color: map-get($typography,  main);
}

button.outline {
  color: map-get($typography, main);
  background-color: transparent;
  border: 1px solid  map-get($typography, main);
}

button.solid {
  color: map-get($typography, main);
  background-color: map-get($background, medium);

  &.dark {
    color: map-get($typography, light);
    background-color: map-get($background, dark);
  }

  &.light {
    color: map-get($typography, main);
    background-color: map-get($background, medium);
  }

  &.remove,
  &.delete {
    background-color: map-get($error, main);
  }
  
  &.add,
  &.create,
  &.update {
    background-color: map-get($background, dark-alternative);
  }

  &.transparent {
    background-color: transparent;
    color: map-get($typography, main);
  }
}

button.disabled {
  background-color: map-get($base-tints, base-4-40);
}

button:disabled {
  cursor: not-allowed;
}
