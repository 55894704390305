@import '../../styles/toolkit';

#careTeamDesktopContent {
  display: flex;
  padding: spacing(7) spacing(3) 0 spacing(13);

  img {
    height: 292px;
    width: auto;
  }

  .flavorText1 {
    margin: spacing(5) spacing(4) 0 spacing(7);

    .howCanWeHelpText {
      margin-bottom: spacing(4);
    }

    .noRequestText {
      margin-bottom: spacing(7);
      line-height: 150%;
    }

    .emailUsButton {
      width: 120px;
      padding: 19 24;
    }
  }

  .flavorText2 {
    // Borders and colors
    border-radius: 4px;
    border: 0.5px solid map-get($base-tints, base-1-40);
    background-color: map-get($base-tints, base-1-60);
    box-shadow: 
      0px 0px 0px 0px rgba(0, 0, 0, 0.04), 0px 1px 1px 0px rgba(0, 0, 0, 0.04), 0px 3px 3px 0px rgba(0, 0, 0, 0.03), 0px 6px 3px 0px rgba(0, 0, 0, 0.02), 0px 10px 4px 0px rgba(0, 0, 0, 0.01), 0px 16px 4px 0px rgba(0, 0, 0, 0.00);

    // Layout
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    margin-top: spacing(5);
    padding: spacing(3);

    ul {
      list-style-position: initial;
      list-style-image: initial;
      list-style-type: auto;
      > li {
        margin-bottom: spacing(1);
      }
    }
  }
}