@import '../../../../styles/toolkit';

.recentActivityListContainer {
  width: auto;
  height: auto;

  &.emptyState {
   width: calc(100% - spacing(2)); 
  }

  >.recentActivityHeader {
    margin-bottom: spacing(2);
  }
}
