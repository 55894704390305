@import "../../styles/toolkit";

div.homeViewContainer {
  // min-height: 100vh;

  .careTeamCard {
    grid-area: careTeamCard;
  }

  div.estateTrackerCard {
    @include flex-center;
    padding: spacing(1);
    margin: 0 0 spacing(4) 0;
  }

  div.homeViewContent {
    display: flex;
    flex-direction: column;
    background-color: map-get($base-colors, base-1);
    position: relative;
    z-index: 1;
    box-shadow: none;
    border-radius: 0;
    
    div.homeViewActionListContainer {
      background-color: map-get($base-colors, base-2);
      padding: spacing(2) spacing(2) spacing(4) spacing(2);
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    div.recentActivityContainer {
      background-color: map-get($base-colors, base-2);
      padding: spacing(2) 0 spacing(4) spacing(2);

      .recentActivityHeader {
        margin-bottom: spacing(2);
      }
    }

    div.keyDatesContainer {
      background-color: map-get($base-colors, base-2);
      padding: spacing(2) spacing(2) spacing(4) spacing(2);

      .keyDatesHeader {
        margin-bottom: spacing(2);
      }
    }
  }
}