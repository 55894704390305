@import '../../../../styles/toolkit';

.chartContainer {
  background-color: map-get($base-colors, base-1);
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 500px;
  padding: spacing(4);

  .estateLinkButton{
    margin-top: spacing(5);
    padding: spacing(1) spacing(3);
    .buttonContent {
      display: flex;
      justify-content: space-between;
      align-items: center;
  
      .svgRightArrowSvgIcon {
        margin-left: spacing(1);
      }
    }
  }
}