@import '../../../../styles/toolkit';

@mixin fontWeights {
  &.regular {
    font-weight: 400;
  }

  &.medium {
    font-weight: 500;
  }

  &.semibold {
    font-weight: 600;
  }
}

.UI1 {
  font-family: 'Haffer';
  margin: 0;

  &.uppercase {
    text-transform: uppercase;
  }
  
  &.lowercase {
    text-transform: lowercase;
  }

  &.capitalize {
    text-transform: capitalize;
  }

  &.wordWrap {
    word-wrap: break-word;
  }

  // Header styles
  &.H1 {
    font-size: typography-sizing(5.6);
    font-weight: 500;
    letter-spacing: -2.24px;
    line-height: 115%;
    
    @include fontWeights;
  }

  &.H2 {
    font-size: typography-sizing(4);
    font-weight: 500;
    line-height: 100%;
    letter-spacing: -1.2px;
    
    @include fontWeights;
  }

  &.H3 {
    font-size: typography-sizing(3.2);
    font-weight: 500;
    letter-spacing: -0.64px;
    line-height: 100%;
    
    @include fontWeights;
  }

  &.H4 {
    font-size: typography-sizing(2.4);
    font-weight: 400;
    letter-spacing: -0.64px;
    line-height: 100%;
    
    @include fontWeights;
  }

  &.H5 {
    font-size: typography-sizing(2.0);
    font-weight: 400;
    letter-spacing: -0.72px;
    line-height: 100%;
    
    @include fontWeights;
  }

  &.H6 {
    font-size: typography-sizing(2);
    font-weight: 400;
    letter-spacing: -0.4px;
    line-height: 100%;
    
    @include fontWeights;
  }

  // Paragraph styles
  &.P1 {
    font-size: typography-sizing(1.6);
    font-weight: 400;
    letter-spacing: -0.48px;
    line-height: 115%;
    
    @include fontWeights;
  }
  
  &.P2 {
    font-size: typography-sizing(1.4);
    font-weight: 400;
    letter-spacing: -0.28px;
    line-height: 100%;
    
    @include fontWeights;
  }
  
  &.P3 {
    font-size: typography-sizing(1.2);
    font-weight: 400;
    letter-spacing: -0.12px;
    line-height: 90%;
    
    @include fontWeights;
  }


  &.L1 {
    font-size: typography-sizing(1.6);
    font-weight: 500;
    letter-spacing: -0.32px;
    line-height: 115%;
    
    @include fontWeights;
  }

  &.L2 {
    font-size: typography-sizing(1.4);
    font-weight: 500;
    line-height: 100%;
    
    @include fontWeights;
  }

  &.L3 {
    font-size: typography-sizing(1.2);
    font-weight: 400;
    line-height: 100%;
    
    @include fontWeights;
  }
}

.UI2 {
  font-family: 'GalaxieCopernicus';
  margin: 0;
  
  .wordWrap {
    word-wrap: break-word;
  }

  // Header styles
  &.H1 {
    font-size: typography-sizing(5.6);
    font-weight: 500;
    letter-spacing: -2.24px;
    line-height: 115%;
    
    @include fontWeights;
  }

  &.H2 {
    font-size: typography-sizing(4);
    font-weight: 500;
    letter-spacing: -1.2px;
    line-height: 100%;
    
    @include fontWeights;
  }

  &.H3 {
    font-size: typography-sizing(3.2);
    font-weight: 500;
    letter-spacing: -0.64px;
    line-height: 100%;
    
    @include fontWeights;
  }

  &.H4 {
    font-size: typography-sizing(3.2);
    font-weight: 400;
    letter-spacing: -0.64px;
    line-height: 115%;
    
    @include fontWeights;
  }

  &.H5 {
    font-size: 2.4rem;
    font-weight: 400;
    letter-spacing: -0.72px;
    line-height: 100%;
    
    @include fontWeights;
  }

  &.H6 {
    font-size: 2rem;
    font-weight: 400;
    letter-spacing: -0.4px;
    line-height: 90%;
    
    @include fontWeights;
  }
}
