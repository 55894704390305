@import "../../../../styles/toolkit";

a.outline,
a.solid,
a.borderless {
  @include flex-center;
  text-decoration: none;
  cursor: pointer;
  border-radius: 100px;
  font-family: Haffer;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  text-transform: none;
  line-height: 26px;
  height: 48px;
  padding: spacing(.5) spacing(1);
  color: map-get($typography,  main);
}

a.outline {
  color: map-get($typography, main);
  background-color: transparent;
  border: 1px solid  map-get($typography, main);
}

a.solid {
  @include flex-center();
  color: map-get($typography, main);
  background-color: map-get($background, medium);

  &.dark {
    color: map-get($typography, light);
    background-color: map-get($background, dark);
  }

  &.light {
    color: map-get($typography, main);
    background-color: map-get($background, medium);
  }

  &.transparent {
    color: map-get($typography, main);
    background-color: transparent;
  }
}