@import '../../../../styles/toolkit';

.cardContainer {
  position: relative;
  width: 100%;
  // height: 100%;
  @include elevation(1);
  background-color: map-get($base-tints, base-1-40);
  border-radius: 8px;
  padding: spacing(3) spacing(2);

  &.clickable {
    // null the default button styles on the inner button text
    color: inherit;
    font: inherit;
    text-transform: none;
    // adjust the focus, active and hover behavior
    &:focus {
      filter: inherit;
    }
  
    &:active {
      filter: brightness(0.9);
    }
  
    &:hover {
      filter: none;
      
      @include respond-to(md) {
        filter: brightness(0.9);
      }
    }
  }


}