@import '../../../styles/toolkit';

.estateComponentDetailsManagementContainer {
  width: 100%;
  height: 100%;
  
  .componentDetailCardListContainer {
    margin-top: 0;
    
    .scrollableList {
      height: calc(72vh - 209px);
    }
  }
  
  .estateComponentDetailsCustomerView {
    z-index: 10;
    .estateComponentSummaryContainer {
      border-radius: 16px 16px 0 0;
    }
  }

}