@import "../../styles/toolkit";

.footerContainer {
  @include flex-column-center();
  width: 100%;
  padding-bottom: 62px;
  
  .footerCard {
    @include flex-column-center();
    width: 100%;
    background-color: map-get($background, dark-alternative);
    border-radius: 0px;

    >.header {
      margin: spacing(6) 0;
      h2 {
        text-align: center;
        color: map-get($typography, accent);
      }
    }

    // > img {
    //   width: 65px;
    //   margin-bottom: spacing(6);
    // }

    .footerBrandIconSvg {
      width: 65px;
      margin-bottom: spacing(6);
    }
  }
}