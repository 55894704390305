@import "../../../styles/toolkit";

div.authLayoutContainer {
  padding: 0;
  min-height: 100vh;
  background-color: map-get($background, dark);

  div.leftPanel {
    display: none;
    visibility: none;
  }

  div.rightPanel {
    height: 100%;
    @include flex-column-start;

    >img.welcomeImage {
      width: 100%;
      height: 229px;
      object-fit: cover;
    }

    div.brandingContainer {
      display: flex;
      justify-content: flex-end;
      margin: spacing(6) spacing(4) spacing(4) 0;
      width: 100%;
      align-items: flex-end;

      .alixLogoPath {
        fill: map-get($primary, main);
      }
    }

  }

  @include respond-to(md) {
    display: flex;
    flex-direction: row;
    height: 100vh;

    >div.leftPanel {
      @include flex-column;
      align-items: center;
      width: 50%;
      height: 100vh;
      background-color: map-get($background, dark);
      background-image: url("/assets/images/d-welcome-image.png");
      background-size: cover;
      background-position: center bottom;

      h3 {
        color: map-get($typography, main);
        font-weight: 400;
        font-size: clamp(spacing(4), 3vw, spacing(6));
        text-align: center;
        &:first-child {
          margin-top: spacing(24);
        }
      }
    }

    >div.rightPanel {
      width: 50%;
      height: 100vh;
      margin-left: spacing(17);
      @include flex-column-start-start;

      .welcomeImage {
        display: none;
      }

      div.brandingContainer {
        margin-top: 0;
        position: fixed;
        right: spacing(14);
        bottom: spacing(14);
      }
    }
  }
}

.versionControl {
  position: absolute;
  bottom: 5px;
  right: 5px;
  color: green;
}
