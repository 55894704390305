h1.MuiTypography-root.MuiTypography-h1, 
h2.MuiTypography-root.MuiTypography-h2, 
h3.MuiTypography-root.MuiTypography-h3, 
h4.MuiTypography-root.MuiTypography-h4, 
h5.MuiTypography-root.MuiTypography-h5 {
  font-family: 'Castoro';
}

h6.MuiTypography-root.MuiTypography-h6,
p.MuiTypography-root.MuiTypography-body1 {
  font-family: 'Karla'
}