@import '../../styles/toolkit';

#estateComponentDetailsDesktop {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  
  >.summaryHeadingContainer {
    width: 100%;
    @include elevation(3);
    margin-top: 0;

    .estateComponentSummaryContainer {
      padding-left: spacing(16);

      .estateComponentDetailsHeadersAndIconContainer {
        max-width: 525px;
      }

      .estateComponentDetailsSummaryContainer {
        max-width: 525px;
      }
    }

    .listHeading {
      background-color: map-get($base-tints, base-2-80);
      padding: 24px 16px;
      padding-left: spacing(17);
    }
  }

  >.cardListContainer {
    padding-left: spacing(16);
    
    .scrollableList {
      height: calc(100vh - 64px - 275px);
      padding-top: spacing(2);
    }
  }

}