@import "../../styles/toolkit";

#desktopPageLayout {
  position: relative;
  height: 100vh;

  display: grid;
  grid-template-columns: 250px auto auto;
  grid-template-rows: 64px 1fr 1fr;
  grid-template-areas:
    "header header header"
    "navbar pageContent pageContent"
    "navbar pageContent pageContent";

  > .pageContent {
    background-color: map-get($base-colors, base-2);
    grid-area: pageContent;
    display: flex;
    flex-direction: column;
  }
}
