@import "../../styles/toolkit";

.careTeamCardContainer {
  width: 100%;

  .careTeamCard {
    display: flex;
    background-color: map-get($background, accent);
    border-radius: 0px;


    img {
      width: 150px;
      height: 200px;
      flex-shrink: 0;
      background-color: map-get($background, light);
      object-fit: cover; /* This will crop the image to fill the box, preserving its aspect ratio */
      object-position: -20px 10px; /* This will position the image to the bottom left */

      @include respond-to(md) {
        width: 180px;
        height: 224px;
      }
    }


    @include respond-to(md) {
      border-radius: 8px;
    }

    .cardBody {
      padding: spacing(3) spacing(1) spacing(1) spacing(3); 

      @include respond-to(md) {
        padding: spacing(6) spacing(4) spacing(1) spacing(4);
      }

      h4 {
        font-size: 28px;
        font-weight: 500;
        margin-bottom: spacing(2);
        color: map-get($typography, alternative);
      }

      > p {
        width: 206px;
        font-size: 16px;
        font-weight: 400;
        margin-bottom: spacing(2);
        color: map-get($typography, alternative);
      }

      .connectButton {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        color: map-get($typography, alternative);
        padding: 0;

        .connectIconContainer {
          margin-right: spacing(1);
        }

        .connectIconBackground {
          background-color: map-get($background, dark-alternative);
        }

        .connectIconPath {
          fill: map-get($typography, accent);
        }

        .connectIconSvg {
          width: 20px;
          height: 20px;
        }

        p {
          color: map-get($typography, alternative);
          font-size: 16px;
          font-weight: 500;
        }
      }
    }
  }
}