@import '../../styles/toolkit';

.managementToolsContainer {
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 16px;
  width: 100%;
  height: 100%;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;

  &.hidden {
    display: none;
  }

  button {
    width: 100%;
    margin: 0 spacing(1);
  }

  &:hover,
  &:focus-within { /* Change the opacity on hover and when any child element is focused */
    opacity: 1;
  }
}