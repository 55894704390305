@import "../../../styles/toolkit";


.scheduleAppointmentContainer {
  @include flex-column-center;

  @include respond-to(md) {
    max-width: 650px;
    align-items: flex-start;
  }
}