@import '../../styles/toolkit';

.designSystemContainer {
  .typography {
    width: 100%;
    background-color: map-get($base-colors, base-1);
    padding: spacing(2);
  }

  .cardsContainer {
    width: 100%;
    background-color: map-get($base-colors, base-2);
    padding: spacing(2);
    
    .cards {
      display: flex;
      flex-direction: column;
      gap: spacing(2);
      max-width: 400px;
    }
  }
}