.contentExplorerContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f3f3f3;
}
.contentExplorerContainer  .bcpr-PreviewHeader-button{
    display: none;
}
.contentExplorerContainer  .bcpr-PreviewHeader-button:last-child{
    display: flex;
}