@import '../../../../styles/toolkit';

.messageCardContainer {
  width: 100%;
  height: 118px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-radius: 6px;
  background-color: map-get($base-colors, base-1);
  
  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
  }
  
  .message {
    color: map-get($base-tints, base-4-60);
  }
}

