
div.estimateCardContainer {
  margin: 10%;
  width: 90%;

  div.internalContainer {
    margin-bottom: 10;
    display: flex;
    align-items: center;
    gap: 5;
  }

  div.divider {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20;
  }
  div.dividerInternalGap {
    display: flex;
    align-items: center;
    gap: 5;
  }

  div.colorEstimatedName {
    color: #838080;
  }
}
.container > * {
  margin-bottom: 5px;
}