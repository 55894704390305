@import "../../../styles/toolkit";

div.documentUploadContainer {
  width: 100%;

  .fileListContainer {
    margin-bottom: spacing(4);
    background-color: map-get($background, medium-light);

    .uploadedHeader {
      display: flex;
      align-items: center;
      gap: spacing(1);
      padding: spacing(2) 0 0 spacing(4);
    }

    @include respond-to(md) {
      background-color: map-get($background, light);
    }

    @include respond-to(md) {
      padding: spacing(4) spacing(6);
    }

    p.fileListTitle {
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      margin-bottom: spacing(2);
    }
  }
  
  .fileListBorders {
    border-top: 0.3px solid map-get($border, default);
    border-bottom: 0.3px solid map-get($border, default);
  }

  .uploadListMobile {
    background-color: map-get($background, medium-light);

    p.uploadListMobileTitle {
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      // padding: spacing(2) 0 0 spacing(4);
    }
    // padding: 0 spacing(4) spacing(4) spacing(4);
    @include respond-to(md) {
      display: none;
      padding: 0;
    }
  }
  
  .uploadListDesktop {
    display: none;

    p.uploadListDesktopTitle {
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      // padding: spacing(2) 0 0 spacing(4);
    }

    @include respond-to(md) {
      display: block;
      // padding: 0 spacing(6) spacing(4) spacing(6);
    }
  }
  
  .fileUploaderContainer {
    padding: 0 spacing(4);
  }
}