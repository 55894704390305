@import "../../../styles/toolkit";


div.formFieldsContainer {
  @include flex-column-center();
  width: 100%;
  
  .MuiTypography-body1 {
    
    margin-bottom: spacing(2);
    width: 100%;

    @include respond-to(md) {
      font-size: 20px;
    }
  }

  p.MuiTypography-root.MuiTypography-body1{
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 19px; /* 126.667% */
    letter-spacing: 0.075px;
    opacity: 0.6;
    padding: 0 spacing(4);
  }

  form {
    margin-bottom: spacing(2);
    @include flex-column-center();
    width: 100%;
    padding: 0 spacing(4);


    @include respond-to(md) {
      align-items: flex-start;
    }

    div.MuiFormControl-root.formFieldsInput {
      width: 100%;
      margin-bottom: spacing(8);
      background-color: map-get($background, light);
    }

    // button.formFieldsActionSubmitButton {
    //   width: 329px;
    // }

  }

  a {
      margin: spacing(5) 0;

    .uploadDocumentButtonText {
      @include flex-center;
      width: 100%;
      padding: 0 spacing(1);

      svg {
        margin-right: spacing(1);
        color: map-get($typography, light);
      }

      .MuiTypography-body1 {
        margin: 0;
        color: map-get($typography, light);
      }
    }
  }
}

// #root > div > div.pageContent > div > div.actionDetailsBody > div.actionContent > div > a > div