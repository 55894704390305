@import '../../../styles/toolkit';

div.loginFormContainer {
  height: 100%;
  width: 100%;
  @include flex-column-start-start;

  .welcomeHeaderContainer {
    padding: spacing(2);
    h1.MuiTypography-h1 {
      font-size: 32px;
      padding-top: spacing(2);
      padding-bottom: spacing(1);
    }

    p.MuiTypography-body1 {
      text-align: left;
    }
  }

  .loadingContainer {
    height: spacing(20);
    width: 100%;
    @include flex-column-center;
  }

  .hide {
    visibility: none;
    display: none;
  }

  form {
    padding: 0 spacing(2);
    width: 100%;

    .MuiFormLabel-root.Mui-error {
      color: map-get($error, light);
    }

    div.MuiTextField-root.emailAddressInput {
      width: 100%;
      color: map-get($typography, light);
    }
    
    div.MuiTextField-root.passwordInput {
      width: 100%;
      color: map-get($typography, light);
    }
    
    div.inputErrorMessage {
      // margin-bottom: spacing(2);
      color: map-get($error, light);
    }

    div.fieldWithMargin {
      margin-bottom: spacing(2);
    }

    div.MuiFormControl-root.hiddenInputContainer {
      width: 100%;

      &:not(:first-child) {
        margin-top: spacing(4);
      }
    }

    div.buttonsContainer {
      @include flex-column-center;
      width: 100%;
      justify-content: center;
      padding: 0;

      .serviceAgreementText {
        margin-bottom: spacing(2);
        font-size: 12px;
      }

      >button.signInButton,
      button.saveNewPasswordButton {
        width: 100%;
        text-transform: none;
        border-radius: 24px;
        height: 48px;
        font-size: 16px;
        margin-top: spacing(2);
      }

      button.signInButton {
        margin-bottom: spacing(3);
      }

      .forgotPasswordLink {
        align-self: flex-start;
        
        p {
          font-size: 12px;
          color: map-get($typography, accent);
        }
      }
    }

    .emailAddressInput:focus, .passwordInput:focus {
      border-bottom-color: map-get($primary, main);
      transition: none;
    }

    .MuiInput-underline:before, .MuiInput-underline:after {
      transition: none;
    }
    .MuiInput-underline:after, .MuiInput-underline:hover:not(.Mui-disabled):before {
      border-bottom: none;
    }

    .MuiIconButton-root svg {
      color: map-get($typography, light);
    }
  }

  @include respond-to(md) {
    @include flex-start;
    padding: 0;
    height: fit-content;
    width: 390px;
    margin-top: spacing(20);

    .welcomeHeaderContainer {
      padding: 0;

      h1.MuiTypography-h1 {
        font-size: 34px;
      }
    }

    >h6.MuiTypography-h6 {
      display: none;
      visibility: hidden;
    }

    form {
      justify-content: flex-start;
      padding: spacing(4) 0;
      div.buttonsContainer {
        >button.signInButton {
          width: 100px;
          align-self: flex-start;
        }

        p {
          max-width: 400px;
          text-align: left;
          align-self: flex-start;

          &.MuiTypography-body1 {
            font-size: 14px;
          }
        }
      }
    }
  }
}