@import '../../styles/toolkit';

div.adminLandingContainer {
  .landingMessage {
    font-weight: 500;
    font-size: 24px;
    padding: spacing(4);
    border-bottom: 1px solid map-get($border, medium );
  }

  >.adminLandingContentContainer {
    display: flex;
    flex-direction: column;
    padding: spacing(4);

    button {
      min-width: 50px;
      max-width: 30%;
      margin-bottom: spacing(2);
    }
  }

}