@import "../../../../../styles/toolkit";


.estateComponentSummarySubtitleContainer {
  display: flex;
  gap: .5rem;
  flex-wrap: wrap;


  p.componentDescriptor {
    font-weight: 500;
  }

  p.componentAccountNumber {
    font-weight: 500;
  }
}