@import '../../styles/toolkit';

.MuiContainer-root.pageNavContainer {
  width: 100%;
  background-color: map-get($background, light );
  z-index: 100;
  padding: 0;
  position: fixed;
  bottom: 0;

  a.careTeamConnectLink {
    position: fixed;
    height: auto;
    bottom: spacing(2);
    right: spacing(2);
    text-decoration: none;
    display: flex;
    flex-direction: column;
    align-items: center;

    &.show {
      @include respond-to(md) {
        display: none;
      }
    }

    &.hide {
      display: none;
    }

    > .careTeamAvatar {
      width: 94px;
      height: 94px;
      margin-bottom: spacing(1);
      padding: spacing(1);
      background-color: map-get($background, light);
      background-image: url('../../assets/images/careteam2-L.png');
      background-repeat: no-repeat;
      background-position: -5px 5px; // This positions the image at the top of the div
      background-size: 110%; // This scales the image to fit the height of the div
      border-radius: 50%;
      border: 2px solid map-get($background, dark);
      overflow: hidden; // This hides any part of the image that falls outside the div
    }

    >.careTeamConnectText {
      font-size: 12px;
      text-align: center;
      background-color: map-get($background, dark);
      @include flex-center;
      padding: spacing(1);
      border-radius: 40px;
      gap: spacing(2);
      width: 90px;
      height: 24px;
    }
  }

  >.MuiContainer-root.linksContainer__inner {
    color: map-get($primary, main);
    width: 100%;
    height: 64px;
    background-color: map-get($background, light);
    z-index: 9999;
    padding: spacing(1) 2em spacing(1) spacing(4);
    display: flex;
    justify-content: space-around;

    &.show {
      display: flex;
    }

    &.hide {
      display: none;

      @include respond-to(md) {
        display: flex;
      }
    }

    a.MuiTypography-root {
      color: map-get($primary, main);

      &:not(:last-child) {
        margin-right: spacing(3);
      }

      .svgNavIcon {
        width: 24px;
        height: 24px;

        path {
          fill: map-get($primary, main);
        }
      }

      .navButtonContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        height: 100%;
        cursor: pointer;


        p {
          font-size: 12px;
          color: map-get($primary, main);
          white-space: nowrap;
        }

        &.selected {
          color: map-get($primary, dark);

          p {
            color: map-get($primary, dark);
          }
          .svgNavIcon {

            path {
              fill: map-get($primary, dark);
            }
          }

        }
      }


      &.careTeamLink {
        color: darkgrey;

        .MuiAvatar-root {
          color: darkgrey;
        }
      }
    }

    @include respond-to(md) {
      width: 100%;
      height: 100%;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, .4);
      padding: spacing(4) spacing(2);

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      gap: spacing(1);

      a.MuiTypography-root {
        margin-right: 0 !important;
        width: 100%;

        .navButtonContainer {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;
          width: 100%;
          margin-bottom: spacing(3);

          svg {
            margin-right: spacing(3);
          }

          p {
            font-size: 16px;
          }
        }
      }
    }
  }

  @include respond-to(md) {
    grid-area: navbar;
    // position: relative;
    position: fixed;
    width: 250px;
    height: calc(100% - 64px);
  }
}