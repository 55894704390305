@import "../../../../styles/toolkit";

.createNotificationByGroupFormContainer {
  padding-left: spacing(4);

  .createNotificationByGroupFormHeader {
    margin-bottom: spacing(4);
  }

  .centeredText {
    text-align: center;
  }
  .linkedToHeader {
    margin-bottom: spacing(2);
  }
  .linkedToMessage {
    margin-bottom: spacing(4);
  }

  .topRowInputsContainer {
    display: flex;
    gap: spacing(2);

    .groupFieldWrapper {
      width: 250px;
      margin-bottom: spacing(2);
    }

    .titleFieldWrapper {
      margin-bottom: spacing(2);
      flex-grow: 1;

      .titleField {
        width: 100%;
      }
    }
  }


  .messageInput {
    border: 1px solid map-get($base-tints, base-4-40);
    border-radius: 8px;
    color: map-get($base-tints, base-4-80);
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    height: 140px;
    letter-spacing: -0.4px;
    line-height: 100%;
    max-width: 100%;
    padding: spacing(2);
    text-wrap: wrap;
    width: 100%;
  }
  
  label {
    color: map-get($base-tints, base-4-80);
  }

  .enablePushFieldWrapper {
    display: flex;
    align-items: center;
  }

  .buttonsContainer {
    display: flex;
    justify-content: flex-end;
    margin-top: spacing(4);

    .button {
      width: 190px;

      &:not(:first-child) {
        margin-left: spacing(2);
      }
    }

    // .sendButton {
    //   background-color: map-get($base-tints, base-4-40);
    // }
  }
}