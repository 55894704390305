@import '../../../../styles/toolkit';

.notificationManagementCardContainer {
  width: 100%;
  height: 100%;

  .cardContent {
    margin-bottom: spacing(2);
    .title {
      margin-bottom: spacing(2);
    }
  }

  .userTagContainer {
    display: flex;
    gap: spacing(1);

    .userTag {
      // width: fit-content;
      padding: spacing(1) spacing(2);
      border-radius: 4px;
      background-color: map-get($accent-colors, accent-6);
    }
  }

  .errorMessageContainer {
    .errorMessage {
      width: fit-content;
      color: map-get($base-colors, base-1);
      padding: spacing(1) spacing(2);
      border-radius: 4px;
      background-color: map-get($alert-colors, alert-1);
    }
  }

  .createdAt {
    margin-top: spacing(2);
    margin-bottom: spacing(2);
    color: map-get($base-colors, base-4);
  }

  .actionButtonContainer {
    display: flex;
    width: 100%;
    justify-content: space-between;

    a.hidden {
      visibility: hidden;
    }
    
    a.visable {
      visibility: visible;
    }
  }
}