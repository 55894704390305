@import '../../../styles/toolkit';

.estateComponentCardContainer {
  border-bottom: 0.3px solid map-get($base-tints, base-4-40);

  @include respond-to(xs) {
    &:last-child {
      border-bottom: none;
    }
  }

  @include respond-to(md) {
    border: 0.3px solid map-get($base-tints, base-4-40);
    border-radius: 8px;
    padding: spacing(2);
    margin: spacing(2) 0;
    width: 393px;

    &:last-child {
      border-bottom: 0.3px solid map-get($base-tints, base-4-40);
    }
  }

  .estateComponentCard {
    display: flex;
    align-items: center;
    padding: spacing(2) 0;

    svg.componentCardStatusIcon {
      width: 30px;
      height: 30px;
      min-width: 30px;
    }
  
    a.componentCardLink {
      width: 100%;
      height: 100%;
      padding: 0 spacing(2);

      &.Pending {
        margin-left: 31px;
      }
  
      .estateComponentCardContent {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;

        @include respond-to(md) {
          min-height: 100px;
          height: 100%;
        }

        .valueMsg {
          display: inline-block;
          margin: spacing(1) 0 spacing(2) 0 ;
        }

        p.estimatedValue {
          margin-left: spacing(1);
          color: map-get($base-tints, base-4-60);
        }

        span.statusMsgBlock {
          white-space: nowrap;

          .statusMsg {
            color: map-get($base-tints, base-4-60);
            font-weight: 500;
  
            &.completed {
              color: map-get($success-colors, success-1);
            }
          }
        }

        .statusMsg.includeMarginTop {
          margin-top: spacing(2);
        }
      }
  
    }
  
  }

  .adminTools {
    @include flex-end;
    justify-content: space-between;
    border-top: 1px solid map-get($border, default);
    padding: spacing(1);
    margin-top: spacing(1);
    width: 100%;

    .adminButtons {
      display: flex;
    }

    button:not(:last-child) {
      margin-right: spacing(1);
    }
  }
}
