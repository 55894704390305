@import '../../styles/toolkit';


.componentSubTypeIconContainer {
  .componentSubTypeIconCircleContainer {
    .componentSubTypeIconBackground {
      background-color: map-get($background, medium);

      &.bordered {
        background-color: none;
      }
    }

    .svg {
      width: 18px;
      height: 18px;
      fill: map-get($typography, main);
      path {
        fill: map-get($typography, main);
      }
    }

  }
}