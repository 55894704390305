@import '../../../../styles/toolkit';

.recentActivityCardContainer {
  display: flex;
  width: 100%;
  height: 187.5px;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: spacing(2);

  .cardTitle {
    display: flex;
    align-items: center;
    margin-bottom: spacing(2);

    p {
      margin-left: spacing(2);
      text-align: left;
    }

    svg {
      box-sizing: border-box;
      width: 32px;
      height: 32px;
      min-width: 32px; // will shrink if this isn't here
    }

    img {
      box-sizing: border-box;
      border-radius: 50%;
      width: 32px;
      height: 32px;
      min-width: 32px; // will shrink if this isn't here
    }
  }

  .cardBody {
    display: flex;
    margin-bottom: spacing(2);
    text-align: left;
    flex-grow: 1;

    .wordWrap {
      word-wrap: break-word;
    }

    // p {
    //   word-break: break-word;
    //   text-align: left;
    //   font-size: 20px;
    //   font-weight: 400;
    //   line-height: 100%;
    //   letter-spacing: -0.4px;
    // }
  }

  .cardFooter {
    display: flex;
    align-items: center;
    align-self: flex-end;
    width: 100%;
    min-height: 10px;
    // position: absolute;
    // bottom: spacing(2);

    &.space-between {
      justify-content: space-between;
    }

    &.flex-end {
      justify-content: flex-end;
    }

    .footerLink {
      display: flex;
      align-items: center;
      gap: spacing(1);
    }
  }
}