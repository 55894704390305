@import '../../../styles/toolkit';

.filePopupOptionsContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: spacing(2);
  
  .optionButtonContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
    &:last-child {
      margin-left: spacing(4);
    }

    p {
      margin-top: spacing(2);
    }
  }

  .popupIconBackground {
    background-color: map-get($background, light);
    width: 64px;
    height: 64px;
    // margin-right: spacing(4);

    :last-child {
      margin-right: 0;
    }

    svg {
      width: 32px;
      height: 32px;
    }
  }
}