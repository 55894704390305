@import '../../../../styles/toolkit';

.componentDetailCardListContainer {
  width: 100%;
  height: 100%;
  padding: 0 spacing(2) spacing(8) spacing(2);

  .scrollableList {
    padding-bottom: 50vh;
  }

  li {
    margin-bottom: spacing(2);
  }
}