@import "../../../../styles/toolkit";

.addExistingUserToEstateFormContainer {
  @include default-form-styling-create-edit();

  min-height: 80vh;
  max-width: 80vw;

  .formContainer > div.inputWrapper {
    margin-bottom: spacing(2);
  }
  
  .inputErrorMessage {
    color: red;
    margin-left: 14px;
    font-size: spacing(1.25);
  }
}